<template>
  <div class="ui-content">
    <div class="ui-box">
      <el-form ref="form" @submit.native.prevent :rules="rules" class="ui-box-from-saveGoods" label-position="right" :model="form" label-width="150px">
        <userInfo :isSee="isSee" :pageType="pageType" :form.sync="form.doctor" :area="area"></userInfo>
        <consultingInfo :isSee="isSee" :pageType="pageType" :form.sync="form.consultingOpen" :tbIntegralConfig.sync="form.tbIntegralConfig"></consultingInfo>
        <practiceInfo :isSee="isSee" :pageType="pageType" :form.sync="form.doctor" :hospitalList="hospitalList"></practiceInfo>
      </el-form>
      <div class="footer">
        <el-button class="ui-box-button" @click="returnList">返回列表</el-button>
        <el-button class="ui-box-button" type="primary" v-show="pageType!=='see'" @click="save">保存医护</el-button>
      </div>
    </div>
  </div>
</template>

<script>
const files = require.context('/', false, /\.vue$/)
const suffixList = files.id.split(' ')[0].split('/').filter((a, b) => b > 2)
const fileName = 'nurseDetail'
const controller = 'doctor'
const parentName = '医护'
import circleInfo from '@/components/statistic/nurse/circleInfo'
import practiceInfo from '@/components/statistic/nurse/practiceInfo'
import consultingInfo from '@/components/statistic/nurse/consultingInfo'
import userInfo from '@/components/statistic/nurse/userInfo'
import { mapGetters } from 'vuex'
export default {
  name: `${suffixList.join('')}${fileName}`,
  data () {
    return {
      hospitalList: [],
      area: {
        areaData: []
      },
      loading: false,
      filePath: ['', ...suffixList, fileName].join('/'),
      fileName: fileName,
      rules: {
      },
      form: {
        tbIntegralConfig: {
          tbId: '',
          tbType: 2,
          tbIsOpenGive: 0,
          tbGiveValue: '',
          tbIsOpenExchange: 0,
          tbExchangeValue: '',
          tbParentId: ''
        },
        consultingOpen: {
          'tbDoctorId': '',
          'tbOnlineState': '',
          'tbIsConsulting': '',
          'tbGraphicConsulting': '',
          'tbGraphicPrice': '',
          'tbPhoneConsulting': '',
          'tbPhonePrice': '',
          'tbPhoneMinutes': '',
          'tbAuditState': '',
          'tbMedicalPackage': ''
        },
        circleInfo: {
          'tbFansTitle': '',
          'tbCircleMasterIntroduce': '',
          'tbCircleIntroduce': '',
          'tbCirclePeople': '',
          'tbCircleUpdateSpeed': '',
          'tbCircleGet': '',
          'tbCircleWarning': ''
        },
        doctor: {
          'tbName': '',
          'tbPhone': '',
          'tbProvinceId': '',
          'tbProvinceName': '',
          'tbCityId': '',
          'tbCityName': '',
          'tbAreaId': '',
          'tbAreaName': '',
          'detailedAddress': '',
          'tbDoctorType': '',
          'tbDoctorStaff': '',
          'tbUserNumber': '',
          'tbIdCard': '',
          'tbInArea': '',
          'tbCardPositive': [],
          'tbCardPositiveTemp': [],
          'tbCardReverse': [],
          'tbCardReverseTemp': [],
          'tbDoctorCard': [],
          'tbDoctorCardTemp': [],
          'tbAppletsQrcode': [],
          'tbAppletsQrcodeTemp': [],
          'tbBigHead': [],
          'tbBigHeadTemp': [],
          'tbHospitalId': '',
          'tbHospitalName': '',
          'tbCertificateNumber': '',
          'tbBecomDoctorYear': '',
          'tbGoodAt': '',
          'tbIntroduce': '',
          'tbDepartmentId': '',
          'tbDepartmentName': '',
          'tbProfessionTitleId': '',
          'tbProfessionTitleName': '',
          'tbGoodAtTitle': ''
        }
      }
    }
  },
  computed: {
    isSee () {
      if (Object.keys(this.lastBreadCrumb.length > 0)) {
        if (['查看医护'].includes(this.lastBreadCrumb[this.fileName])) {
          return true
        }
      }
      return false
    },
    pageType () {
      if (Object.keys(this.lastBreadCrumb.length > 0)) {
        if (['新增医护'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'add'
        } else if (['修改医护'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'edit'
        } else if (['查看医护'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'see'
        }
      }
      return 'add'
    },
    ...mapGetters([
      'lastBreadCrumb',
      'refreshPage'
    ])
  },
  watch: {
    'form.doctor.tbAreaId': function (val) {
      if (val) {
        this.getHospitalList()
      } else {
        this.hospitalList = []
      }
    },
    $route (to, from) {
      if (to.path === this.filePath && this.refreshPage) {
        this.init()
        this.$store.dispatch('setRefreshPage', false)
      }
    }
  },
  methods: {
    setPhoto (data, filed) {
      if (data[filed]) {
        data[`${filed}Temp`] = data[filed] = data[filed].split(',').map(i => {
          return {
            name: '',
            url: this.setUrlOss(i)
          }
        })
      } else {
        data[`${filed}Temp`] = data[filed] = []
      }
    },
    getHospitalList () {
      this.$http.get('/hospital/selectByArea', { areaId: this.form.doctor.tbAreaId })
        .then(res => {
          if (res.code === 1000) {
            this.hospitalList = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    clear () {
      this.form = {
        tbIntegralConfig: {
          tbId: '',
          tbType: 2,
          tbIsOpenGive: 0,
          tbGiveValue: '',
          tbIsOpenExchange: 0,
          tbExchangeValue: '',
          tbParentId: ''
        },
        consultingOpen: {
          'tbDoctorId': '',
          'tbOnlineState': '',
          'tbIsConsulting': '',
          'tbGraphicConsulting': '',
          'tbGraphicPrice': '',
          'tbPhoneConsulting': '',
          'tbPhonePrice': '',
          'tbPhoneMinutes': '',
          'tbAuditState': '',
          'tbMedicalPackage': ''
        },
        circleInfo: {
          'tbFansTitle': '',
          'tbCircleMasterIntroduce': '',
          'tbCircleIntroduce': '',
          'tbCirclePeople': '',
          'tbCircleUpdateSpeed': '',
          'tbCircleGet': '',
          'tbCircleWarning': ''
        },
        doctor: {
          'tbName': '',
          'tbPhone': '',
          'tbProvinceId': '',
          'tbProvinceName': '',
          'tbCityId': '',
          'tbCityName': '',
          'tbAreaId': '',
          'tbAreaName': '',
          'detailedAddress': '',
          'tbDoctorType': '',
          'tbDoctorStaff': '',
          'tbUserNumber': '',
          'tbIdCard': '',
          'tbInArea': '',
          'tbCardPositive': [],
          'tbCardPositiveTemp': [],
          'tbCardReverse': [],
          'tbCardReverseTemp': [],
          'tbDoctorCard': [],
          'tbDoctorCardTemp': [],
          'tbAppletsQrcode': [],
          'tbAppletsQrcodeTemp': [],
          'tbBigHead': [],
          'tbBigHeadTemp': [],
          'tbHospitalId': '',
          'tbHospitalName': '',
          'tbCertificateNumber': '',
          'tbBecomDoctorYear': '',
          'tbGoodAt': '',
          'tbIntroduce': '',
          'tbDepartmentId': '',
          'tbDepartmentName': '',
          'tbProfessionTitleId': '',
          'tbProfessionTitleName': '',
          'tbGoodAtTitle': ''
        }
      }
    },
    init () {
      if (this.pageType !== 'add') {
        this.getById(this.$route.query.tbId)
      } else {
        this.clear()
      }
    },
    beforeSave (form) {
      const temp = ['tbCardPositive', 'tbCardReverse', 'tbDoctorCard', 'tbBigHead']
      temp.map(i => {
        form.doctor[i] = form.doctor[i].map(j => j.url.replace(/.*?https:\/\/hyl-ymm.oss-cn-beijing.aliyuncs.com/, '')).join(',')
        delete form.doctor[`${i}Temp`]
      })
      if (form.doctor.tbAreaId) {
        form.doctor.tbInArea = `${form.doctor.tbProvinceName}${form.doctor.tbCityName}${form.doctor.tbAreaName}`
      }
      form.doctor.tbOnlineState = form.consultingOpen.tbOnlineState
      form.consultingOpen.tbDoctorId = form.doctor.tbId
      delete form.consultingOpen.tbOnlineState
      delete form.doctor.tbAppletsQrcode
      return form
    },
    initForm (form) {
      form.doctor = form.doctor ? form.doctor : this.form.doctor
      form.circleInfo = form.circleInfo ? form.circleInfo : this.form.circleInfo
      form.consultingOpen = form.consultingOpen ? form.consultingOpen : this.form.consultingOpen
      this.setPhoto(form.doctor, 'tbCardPositive')
      this.setPhoto(form.doctor, 'tbCardReverse')
      this.setPhoto(form.doctor, 'tbDoctorCard')
      if (form.doctor.tbAppletsQrcode) {
        form.doctor.tbAppletsQrcodeTemp = form.doctor.tbAppletsQrcode = [{ name: '', url: this.$http.appHeadPicUrl + form.doctor.tbAppletsQrcode }]
      } else {
        form.doctor.tbAppletsQrcodeTemp = form.doctor.tbAppletsQrcode = []
      }
      this.setPhoto(form.doctor, 'tbBigHead')
      form.consultingOpen.tbOnlineState = form.doctor.tbOnlineState
      delete form.doctor.tbOnlineState
      if (form.doctor.tbAreaId) {
        this.area.areaData = [form.doctor.tbProvinceId, form.doctor.tbCityId, form.doctor.tbAreaId]
      }
      if (!form.tbIntegralConfig) {
        form.tbIntegralConfig = {
          tbId: '',
          tbType: 2,
          tbIsOpenGive: 0,
          tbGiveValue: '',
          tbIsOpenExchange: 0,
          tbExchangeValue: '',
          tbParentId: form.doctor.tbId
        }
      }
      return form
    },
    getById (id) {
      this.$http.get(`${controller}/getCustomDoctor2ById`, { 'tbId': id })
        .then(res => {
          if (res.code === 1000) {
            this.form = this.initForm(res.data)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    save () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form))
          form = this.beforeSave(form)
          this.$http.post(`${controller}/updateTbDoctor`, form)
            .then(res => {
              if (res.code === 1000) {
                this.returnList()
              } else {
                this.$message.error(res.message)
              }
            })
        } else {
          return false
        }
      })
    },
    returnList () {
      this.closeTab(this.filePath, parentName, this.fileName, ['', ...suffixList].join('/'), true)
    }
  },
  created () {
    this.init()
  },
  mounted () {
  },
  destroyed () {
  },
  components: {
    userInfo,
    consultingInfo,
    practiceInfo,
    circleInfo
  }
}
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    overflow: hidden;
    height: 100%;
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-saveGoods {
      overflow-y: auto;
      height: calc(100% - 92px);
    }
  }
  .footer {
    width: 100%;
    margin: 40px 0 40px 0;
    text-align: center;
    .ui-box-button {
      padding: 10px 25px;
    }
  }
}
</style>