import { render, staticRenderFns } from "./prescriptionDetail.vue?vue&type=template&id=70e76cec&scoped=true"
import script from "./prescriptionDetail.vue?vue&type=script&lang=js"
export * from "./prescriptionDetail.vue?vue&type=script&lang=js"
import style0 from "./prescriptionDetail.vue?vue&type=style&index=0&id=70e76cec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e76cec",
  null
  
)

export default component.exports