<template>
  <div class="ui-upload-box" style=" display: inline-block;">
    <eluploadLocal ref="upload" class="ui-upload custom inline" :http-request="httpRequest" :accept="accept" :class="{uploadSee:isSee,isOne:limit===1&&saveFileList.length===1}" :before-upload="onBeforeUpload" :action="UploadUrl()" :multiple="multiple" :limit="limit" :file-list="fileList" :on-exceed="handleExceed" :on-success="handleSuccess" :headers="file.headers" :data="file.data" :list-type="listType" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
      <template v-if="listType=='picture-card'" slot="file" slot-scope="{file}">
        <el-progress type="circle" v-if="file.status === 'uploading'" :percentage="file.percentage" :width="uploadSize"></el-progress>
        <el-image :ref="file.url" class="el-upload-list__item-thumbnail" v-if="isPic&&file.status==='success'" :src="file.url" alt="" @error="lodingImg" :preview-src-list="[file.url]"></el-image>
        <el-image :ref="file.url" class="el-upload-list__item-thumbnail" v-if="isVideo&&file.status==='success'" :src="getVideoPic(file)" alt="" @error="lodingImg" :preview-src-list="[file.url]"></el-image>
        <span v-if="listType=='picture-card'" class="el-upload-list__item-actions">
          <span @click="handlePictureCardPreview(file.url)">
            <i v-if="!isVideo" class="el-icon-zoom-in"></i>
            <i v-if="isVideo" class="el-icon-video-play"></i>
          </span>
          <span v-if="!disabled" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </template>
      <i v-if="listType=='picture-card'" class="el-icon-plus"></i>
      <el-button v-else size="small" type="primary">点击上传</el-button>
      <i class="custom-label" v-if="customLabel!=''">{{customLabel}}</i>
    </eluploadLocal>
    <!-- <el-button type="primary" @click="stop">暂停</el-button>
    <el-button type="primary" @click="resume">继续</el-button> -->
    <el-dialog append-to-body :title="isVideo?'预览视频':'预览图片'" top="30vh" custom-class="ui-pic-box-dialog" :visible.sync="dialogVisible" :before-close="dialogBeforeClose">
      <img v-if="!isVideo" class="" height="100%" style="object-fit:scale-down;" :src="dialogImageUrl" alt="" @error="errorImg">
      <video v-if="isVideo" ref="videoRefs" class="videoBox" height="100%" controls autoplay name="media">
        <source :src="dialogImageUrl">
      </video>
      <span slot="footer" class="dialog-footer">
        <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
        <el-button class="ui-box-submit" type="primary" @click="dialogBeforeClose">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'upload',
  props: {
    fileList: Array,
    isPic: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isVideo: {
      type: Boolean,
      default: false
    },
    saveFileList: {
      type: Array,
      default: () => []
    },
    uploadSize: {
      type: Number,
      default: 180
    },
    customLabel: {
      type: String,
      default: ''
    },
    validateRef: {
      type: String,
      default: ''
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    cumputeVideoSize: {
      type: Boolean,
      default: false
    },
    cumputePicSize: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      default: () => {}
    },
    onRemove: {
      type: Function,
      default: () => {}
    },
    folder: {
      type: String,
      default: 'addGoods'
    },
    isRandomfolder: {
      type: Boolean,
      default: true
    },
    isOssDelete: {
      type: Boolean,
      default: false
    },
    limit: Number,
    isSee: Boolean
  },
  data() {
    return {
      isCancel: false,
      checkpoints: {},
      folderPath: '',
      dialogVisible: false,
      dialogImageUrl: '',
      isRemove: true,
      accept: '',
      file: {
        item: null,
        headers: {
          Authorization: window.sessionStorage.getItem('token') || ''
        },
        data: {
          fileName: '',
          folder: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['stsToken', 'ossClient'])
  },
  mounted() {
    this.$refs.upload.$el.style.setProperty(
      '--width-varUploadSize',
      `${this.uploadSize}px`
    )
    if (this.isRandomfolder) {
      this.folderPath = `${this.folder}/${new Date().format(
        'yyyy-MM-dd'
      )}/${this.uuid()}`
      this.file.data.folder = this.folderPath
    } else {
      this.folderPath = this.folder
      this.file.data.folder = this.folderPath
    }
    if (!this.ossClient) {
      this.createOssClient()
    }
  },
  watch: {
    folder() {
      if (this.isRandomfolder) {
        this.folderPath = `${this.folder}/${new Date().format(
          'yyyy-MM-dd'
        )}/${this.uuid()}`
        this.file.data.folder = this.folderPath
      } else {
        this.folderPath = this.folder
        this.file.data.folder = this.folderPath
      }
    },
    dialogImageUrl(val) {
      if (this.isVideo && this.$refs.videoRefs) {
        this.$refs.videoRefs.src = this.dialogImageUrl
      }
    }
  },
  created() {
    if (this.isPic) {
      this.accept = 'image/*'
    } else if (this.isVideo) {
      this.accept = 'video/*'
    } else {
      this.accept = ''
    }
  },
  methods: {
    ...mapActions(['setStsToken', 'setOssClient']),
    stop() {
      this.isCancel = true
    },
    async resume() {
      this.isCancel = false
      const { expiration } = this.stsToken
      if (
        expiration &&
        new Date(expiration).getTime() - new Date().getTime() <=
          60 * 10 * 1000 &&
        this.ossClient
      ) {
        await this.setStsToken()
        await this.setOssClient()
      }
      this.resumeMultipartUpload()
    },
    async createOssClient() {
      await this.setStsToken()
      await this.setOssClient()
    },
    onMultipartUploadProgress(onProgress, onSuccess, onError) {
      return async (progress, checkpoint, res) => {
        if (checkpoint) {
          checkpoint.onProgress = onProgress
          checkpoint.onSuccess = onSuccess
          checkpoint.onError = onError
          this.checkpoints[checkpoint.uploadId] = checkpoint
        }
        const { expiration } = this.stsToken
        if (this.isCancel && this.ossClient) {
          this.ossClient.cancel()
        } else if (
          expiration &&
          new Date(expiration).getTime() - new Date().getTime() <=
            60 * 10 * 1000 &&
          this.ossClient
        ) {
          this.ossClient.cancel()
          await this.setStsToken()
          await this.setOssClient()
          this.resumeMultipartUpload()
        }
        return onProgress({ percent: Math.ceil(progress * 100) })
      }
    },
    async resumeMultipartUpload() {
      Object.values(this.checkpoints).forEach((checkpoint) => {
        const { uploadId, file, onProgress, onSuccess, onError } = checkpoint
        delete checkpoint.onProgress
        delete checkpoint.onSuccess
        delete checkpoint.onError
        this.ossClient
          .multipartUpload(uploadId, file, {
            parallel: 3,
            partSize: 1024 * 1024,
            progress: this.onMultipartUploadProgress(
              onProgress,
              onSuccess,
              onError
            ),
            checkpoint
          })
          .then((res) => {
            delete this.checkpoints[checkpoint.uploadId]
            file.status = 'success'
            let url = res.res.requestUrls[0]
            if (url.includes('https://hyl-ymm.oss-cn-beijing.aliyuncs.com/')) {
              url = url.substr(
                'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/'.length,
                url.length
              )
            }
            if (url.includes('?')) {
              url = url.substr(0, url.indexOf('?'))
            }
            onSuccess({
              code: 1000,
              message: url,
              success: true
            })
          })
          .catch((err) => {
            if (err?.name !== 'cancel') {
              onError(err)
            }
          })
      })
    },
    uuid() {
      return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function (c) {
        return ((Math.random() * 16) | 0).toString(16)
      })
    },
    httpRequest({ file, data, onProgress, onSuccess, onError }) {
      this.ossClient
        .multipartUpload(
          `${process.env.VUE_APP_OSS_PREFIX}${this.folderPath}/${file.name}`,
          file,
          {
            // this.ossClient.multipartUpload(`test/counsel/${file.name}`, file, {
            parallel: 3,
            partSize: 1024 * 1024,
            progress: this.onMultipartUploadProgress(
              onProgress,
              onSuccess,
              onError
            )
          }
        )
        .then((res) => {
          file.status = 'success'
          let url = res.res.requestUrls[0]
          if (url.includes('https://hyl-ymm.oss-cn-beijing.aliyuncs.com/')) {
            url = url.substr(
              'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/'.length,
              url.length
            )
          }
          if (url.includes('?')) {
            url = url.substr(0, url.indexOf('?'))
          }
          onSuccess({
            code: 1000,
            message: url,
            success: true
          })
        })
        .catch((err) => {
          if (err?.name !== 'cancel') {
            onError(err)
          }
        })
    },
    dialogBeforeClose() {
      if (this.isVideo && this.$refs.videoRefs) {
        this.$refs.videoRefs.pause()
        this.dialogImageUrl = null
      }
      this.dialogVisible = false
    },
    getVideoPic(file) {
      return file.response
        ? 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/' +
            file.response.message +
            '?x-oss-process=video/snapshot,t_1,f_jpg,w_800,h_800,m_fast'
        : file.url +
            '?x-oss-process=video/snapshot,t_1,f_jpg,w_800,h_800,m_fast'
    },
    onBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        if (this.isPic && !file.type.includes('image')) {
          this.$message.error('请上传正确的图片格式')
          reject()
        }
        if (this.isVideo && !file.type.includes('video')) {
          this.$message.error('请上传正确的视频格式')
          reject()
        }
        if (this.cumputePicSize) {
          const name = file.name
          const _this = this
          const reader = new FileReader()
          reader.onload = function (e) {
            const txt = e.target.result
            const img = document.createElement('img')
            img.src = txt
            img.onload = function () {
              const len = name.lastIndexOf('.')
              const prefix = name.substring(0, len)
              const suffix = name.substring(len + 1)
              _this.file.data.fileName = `${prefix}&width=${img.width}&height=${img.height}.${suffix}`
              resolve()
            }
          }
          reader.readAsDataURL(file)
        } else {
          this.file.data.fileName = file.name
          resolve()
        }
      })
    },
    base64toFile(baseUrl, filename = 'file') {
      const arr = baseUrl.split(',')
      const type = arr[0].match(/:(.*?);/)[1] // 解锁图片类型
      const bytes = atob(arr[1]) // 解码base64
      let n = bytes.length
      const bufferArray = new Uint8Array(n)
      while (n--) {
        bufferArray[n] = bytes.charCodeAt(n)
      }
      return new File([bufferArray], filename, { type })
    },
    getFileName(file, width, height) {
      const type = file.raw.type.split('/')[1]
      let name = ''
      if (type) {
        name = file.raw.name.substring(0, file.raw.name.indexOf(`.${type}`))
      }
      this.file.data.fileName = `${name}&width=${width}&height=${height}.png`
      return `${name}&width=${width}&height=${height}.png`
    },
    handleSuccess(data, file, fileList) {
      if (data.code === 1000) {
        this.saveFileList.push({
          name: '',
          url: 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/' + data.message
        })
        this.$message.success('上传成功')
        if (this.isVideo && this.cumputeVideoSize) {
          const video = document.createElement('video')
          video.src = file.url
          var canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          video.crossOrigin = 'anonymous'
          video.currentTime = 1
          video.oncanplay = () => {
            canvas.width = video.videoWidth ? video.videoWidth : 320 // 获取视频宽度
            canvas.height = video.videoHeight ? video.videoHeight : 320 //获取视频高度
            // 利用canvas对象方法绘图
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
            // 转换成base64形式
            this.videoFirstimgsrc = canvas.toDataURL('image/png') // 截取后的视频封面
            file.url = this.videoFirstimgsrc
            // base64转成bolb文件
            const fileName = this.getFileName(file, canvas.width, canvas.height)
            const fileBolb = this.base64toFile(this.videoFirstimgsrc, fileName)
            // 把首图上传生成云上地址
            const formData = new FormData()
            formData.append('file', fileBolb)
            formData.append('fileName', fileName)
            formData.append('folder', this.folderPath)
            this.$http.upload('upload', formData).then((res) => {
              this.file.data.fileName = ''
              this.$emit('onSuccess', this.validateRef, res)
            })
            video.remove()
            canvas.remove()
          }
        } else {
          this.$emit('onSuccess', this.validateRef)
        }
      } else {
        this.$message.error('上传失败,请联系管理员')
      }
    },
    handleRemove(file, fileList) {
      if (this.isRemove) {
        this.isRemove = false
        this.$refs.upload.handleRemove(file)
      } else {
        this.isRemove = true
        let url = file.response ? file.response.message : file.url
        const path = file.response ? file.response.message : file.url
        if (url.includes('https://hyl-ymm.oss-cn-beijing.aliyuncs.com/')) {
          url = url.substr(
            'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/'.length,
            url.length
          )
        }
        this.$emit('onRemove', this.onRemove)
        if (this.isOssDelete) {
          this.$http
            .post('/deleteFile', { path: url }, 'form')
            .then((res) => {})
        }
        this.$emit(
          'update:saveFileList',
          this.saveFileList.filter((i) => !i.url.includes(path))
        )
      }
    },
    handleExceed() {
      this.$message.error('上传数量超出允许最大上传数量')
    },
    handlePictureCardPreview(ref) {
      if (this.isVideo) {
        this.dialogImageUrl = ref
        this.dialogVisible = true
      }
      if (this.isPic) {
        this.$refs[ref].clickHandler()
      }
      if (this.listType != 'picture-card') {
        const url = ref?.response?.message || ref?.url
        window.open(this.setUrlOss(url))
      }
    },
    UploadUrl() {
      return process.env.VUE_APP_URL + 'upload'
    }
  }
}
</script>

<style lang="scss" scoped>
$varUploadSize: var(--width-varUploadSize);
.ui-upload-box {
  .ui-upload.custom {
    &.isOne {
      ::v-deep .el-upload--picture-card {
        display: none;
      }
    }
    &.uploadSee {
      ::v-deep .el-upload--picture-card {
        display: none;
      }
      ::v-deep .el-upload__tip {
        display: none;
      }
      ::v-deep .el-upload-list__item-delete {
        display: none;
      }
    }
    &.inline {
      display: inline-block;
      margin-right: 10px;
    }
    --width-varUploadSize: 180px;
    ::v-deep .el-upload--picture-card {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ::v-deep .el-icon-plus {
        margin-top: 10px;
      }
      ::v-deep .custom-label {
        padding-top: 10px;
        height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
      }
    }
    ::v-deep .el-upload-list--picture-card {
      display: inline-block;
      height: $varUploadSize;
      ::v-deep .el-progress {
        width: $varUploadSize;
        ::v-deep .el-icon-check {
          color: #000;
        }
      }
      ::v-deep .el-upload-list__item {
        width: $varUploadSize;
        height: $varUploadSize;
        line-height: calc(#{$varUploadSize} - 3px);
        margin-bottom: 0;
        ::v-deep .el-icon-close-tip {
          display: none;
        }
      }
      ::v-deep .el-upload-list__item-status-label {
        ::v-deep i {
          position: absolute;
          right: 15px;
        }
      }
      ::v-deep .el-upload-list__item:focus {
        outline: none;
      }
      ::v-deep .el-upload-list__item-thumbnail {
        height: 103px !important;
      }
      ::v-deep .el-upload-list__item-thumbnail:focus {
        outline: none;
      }
    }
    ::v-deep .el-upload--picture-card {
      width: $varUploadSize;
      height: $varUploadSize;
      line-height: calc(#{$varUploadSize} - 2px);
    }
  }
}
::v-deep .ui-pic-box-dialog {
  border-radius: 12px;
  height: 50%;
  text-align: center;
  ::v-deep .el-icon-close:focus,
  ::v-deep .el-dialog__headerbtn:focus {
    outline: none;
  }
  ::v-deep .el-dialog__body {
    overflow: auto;
    height: calc(100% - 130px);
    .videoBox {
      width: 100%;
      height: calc(100% - 11px);
      &:focus {
        outline: none;
      }
    }
  }
}
.ui-upload-box {
  .ui-upload.custom {
    &.isOne {
      ::v-deep .el-upload--picture-card {
        display: none;
      }
    }
    &.uploadSee {
      ::v-deep .el-upload--picture-card {
        display: none;
      }
      ::v-deep .el-upload__tip {
        display: none;
      }
      ::v-deep .el-upload-list__item-delete {
        display: none;
      }
    }
    &.inline {
      display: inline-block;
      margin-right: 10px;
    }
    --width-varUploadSize: 180px;
    ::v-deep .el-upload--picture-card {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .el-icon-plus {
        margin-top: 10px;
      }
      .custom-label {
        padding-top: 10px;
        height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
      }
    }
    ::v-deep .el-upload-list--picture-card {
      display: inline-block;
      height: $varUploadSize;
      .el-progress {
        width: $varUploadSize;
        .el-icon-check {
          color: #000;
        }
      }

      .el-upload-list__item {
        width: $varUploadSize;
        height: $varUploadSize;
        line-height: calc(#{$varUploadSize} - 3px);
        margin-bottom: 0;
        .el-icon-close-tip {
          display: none;
        }
      }
      .el-upload-list__item-status-label {
        i {
          position: absolute;
          right: 15px;
        }
      }
      .el-upload-list__item:focus {
        outline: none;
      }
      .el-upload-list__item-thumbnail:focus {
        outline: none;
      }
    }
    ::v-deep .el-upload--picture-card {
      width: $varUploadSize;
      height: $varUploadSize;
      line-height: calc(#{$varUploadSize} - 2px);
    }
  }
}
::v-deep .ui-pic-box-dialog {
  border-radius: 12px;
  height: 50%;
  text-align: center;
  .el-icon-close:focus,
  .el-dialog__headerbtn:focus {
    outline: none;
  }
  .el-dialog__body {
    overflow: auto;
    height: calc(100% - 130px);
    .videoBox {
      width: 100%;
      height: calc(100% - 11px);
      &:focus {
        outline: none;
      }
    }
  }
}
</style>
     