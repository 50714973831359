<template>
  <div class="ui-order-goodsInfo">
    <div class="ui-order-goodsInfo-title">
      <span>商品信息</span>
      <span v-if="data.prescriptionId!==0&&data.prescriptionId" class="ui-order-goodsInfo-title-button" @click="openTab('处方详情', 'prescriptionDetail', '/statistic/orderList/prescriptionDetail' ,{tbId:data.prescriptionId})">查看处方</span>
    </div>
    <el-table :data="data.orderGoods" border style="width: 100%" :header-cell-style="{background: '#f2f6fc',color: '#000000','font-weight': 500,'font-family': 'Microsoft YaHei'}">
      <el-table-column v-if="['/order/exchangeProcessing/exchangeReasonDetail','/order/returnProcessing/returnProcessingDetail'].includes($route.path)" align="center" min-width="22.3%" prop="tbGoodsCode" label="商品编号">
      </el-table-column>
      <el-table-column align="center" min-width="11.7%" prop="tbListMinFigure" label="商品图片">
        <template slot-scope="{row}">
          <img :src="getUrl(row.tbListMinFigure)" class="ui-box-tableList-img" alt="" @error="errorImg">
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="22.3%" prop="tbGoodsName" label="商品名称">
        <template slot-scope="{row}">
          {{row.tbGoodsName}}
        </template>
      </el-table-column>
      <el-table-column align="center" v-if="['/order/orderList/orderDetail'].includes($route.path)" min-width="10.01%" prop="tbSpecificationsCode" label="SKU编号">
      </el-table-column>
      <el-table-column align="center" min-width="13.3%" label="单价">
        <template slot-scope="{row}">
          <div>原价：￥{{row.tbOriginalPrice}}</div>
          <div>折扣价：￥{{row.tbDiscountPrice}}</div>
          <div>实付价：￥{{row.tbPrice}}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="11%" prop="tbSpecificationsName" label="规格">
      </el-table-column>
      <el-table-column align="center" min-width="5.6%" prop="tbNumber" label="数量">
      </el-table-column>
      <el-table-column align="center" min-width="8%" prop="7" label="小计">
        <template slot-scope="{row}">￥{{row.tbPrice}}</template>
      </el-table-column>
      <el-table-column align="center" min-width="16.66%" prop="7" label="助理指派">
        <template slot-scope="{row}">
          <span v-if="row.distributionStatus==='1'">无需指派</span>
          <span v-else-if="row.distributionStatus==='3'">
            <el-button type="primary" @click="chooseStaff(row)">指派</el-button>
          </span>
          <span v-else>
            {{row.distributionStatus}}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="8%" prop="tbShopInfoName" label="自提点" show-overflow-tooltip>
        <template slot-scope="{row}">{{row.tbShopInfoName?row.tbShopInfoName:'无'}}</template>
      </el-table-column>
    </el-table>
    <table class="ui-order-goodsInfo-table" v-if="data.tbIsBuyHire===1">
      <tr>
        <th>押金</th>
        <th>日租金</th>
        <th>租期</th>
        <th>归还最低租金</th>
        <th>最高租赁期限</th>
      </tr>
      <tr>
        <td>{{hireGoods.tbPrice}}</td>
        <td>{{hireGoods.tbRentPrice}}</td>
        <td>{{hireGoods.tbHireLowerDays}}</td>
        <td>{{hireGoods.tbHireLowerPrice}}</td>
        <td>{{hireGoods.tbHireUpperDays}}</td>
      </tr>
    </table>
    <div class="ui-order-goodsInfo-all red">￥{{allCost}}</div>
    <div class="ui-order-goodsInfo-all">合计:</div>
    <el-dialog title="添加指派" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="984px" :before-close="dialogBeforeClose">
      <el-form label-position="right" label-width="80px">
        <el-form-item label="指派" prop="tbCityId">
          <el-cascader clearable ref="areaRef" popper-class="cascader-tree" :change-on-select="true" v-model="areaIds" :options="areaOptions" :props="areaProps" @change="cascaderChange"></el-cascader>
          <el-select style="margin-left: 50px;" v-model="staffId" placeholder="请选择助理">
            <el-option v-for="(item,index) in  staffList" :key="index" :label="item.nickname" :value="item.tbId"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
        <el-button class="ui-box-submit" type="primary" @click="appointDistributionOrderGoods">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'goodsInfo',
  props: {
    data: Object
  },
  watch: {
    $route (to, from) {
      for (const i in this.isShow) {
        this.isShow[i] = false
      }
      if (this.isShow[to.path]) {
        this.isShow[to.path] = true
      }
    }
  },
  computed: {
    hireGoods () {
      if (this.data.orderGoods) {
        return this.data.orderGoods[0]
      } else {
        return {}
      }
    },
    areaOptions () {
      if (this.areaTree.length > 0) {
        return this.areaTree[0].children
      }
      return []
    },
    allCost () {
      return this.data.orderGoods && this.data.orderGoods.reduce((res, { tbPrice, tbNumber, tbRentPrice, tbHireLowerDays }) => {
        if (this.data.tbIsBuyHire === 1) {
          res += tbPrice + tbRentPrice * tbHireLowerDays
        } else {
          res += tbPrice
        }
        return res
      }, 0) || 0
    },
    ...mapGetters([
      'lastBreadCrumb',
      'areaTree'
    ])
  },
  data () {
    return {
      orderGoodsId: '',
      tbPositionType: '',
      staffId: '',
      goodsId: '',
      level: '',
      cityId: '',
      staffList: [],
      areaIds: [],
      areaProps: {
        value: 'id',
        label: 'areaName',
        children: 'children'
      },
      dialogVisible: false,
      isShow: {
        '/order/orderList/orderDetail': false,
        '/order/exchangeProcessing/exchangeReasonDetail': false,
        'order/returnProcessing/returnProcessingDetail': false
      }
    }
  },
  methods: {
    chooseStaff (row) {
      this.goodsId = row.tbGoodsId
      this.orderGoodsId = row.tbId
      this.dialogVisible = true
    },
    appointDistributionOrderGoods () {
      if (this.staffId) {
        const { tbPositionType, tbGoodsStaffId } = this.staffList.find(i => i.tbId === this.staffId)
        this.$http.post('/orderGoods/appointDistributionOrderGoods', {
          tbGoodsStaffId: tbGoodsStaffId,
          orderGoodsId: this.orderGoodsId,
          staffId: this.staffId,
          tbPositionType: tbPositionType
        })
          .then(res => {
            if (res.code === 1000) {
              this.$message('指派成功')
              this.dialogBeforeClose()
              this.$emit('updateInfo')
            } else {
              this.$message.error(res.message)
            }
          })
      } else {
        this.$message('请先选择助理')
      }
    },
    getStaffList () {
      this.$http.get('/staff/getStaffByAreaGoods', {
        goodsId: this.goodsId,
        cityId: this.cityId,
        level: this.level
      })
        .then(res => {
          if (res.code === 1000) {
            this.staffList = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    cascaderChange (val) {
      if (val.length === 0) {
        this.level = ''
        this.cityId = ''
        this.staffId = ''
      } else if (val.length === 1) {
        this.level = 1
        this.cityId = [...val].pop()
        this.getStaffList()
      } else if (val.length === 2) {
        this.level = 2
        this.cityId = [...val].pop()
        this.getStaffList()
      } else if (val.length === 3) {
        this.level = 3
        this.cityId = [...val].pop()
        this.getStaffList()
      }
    },
    dialogBeforeClose () {
      this.dialogVisible = false
    }
  },
  beforeDestroy () {
  },
  mounted () {
    if (this.areaTree.length === 0) {
      this.$store.dispatch('getAreaTree', this)
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-order-goodsInfo {
  margin: 0 24px 92px;
  width: calc(100% - 48px);
  height: auto;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  .ui-order-goodsInfo-title {
    margin-left: 4px;
    margin-bottom: 10px;
    width: 100%;
    height: 33px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
    .ui-order-goodsInfo-title-button {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      padding: 5px 16px;
      background: #f7b500;
      border-radius: 2px;
    }
  }
  .ui-order-goodsInfo-table {
    table-layout: fixed;
    text-align: center;
    width: 100%;
    border: 1px solid #dae9ff;
    border-collapse: collapse;
    tr {
      border: 1px solid #dae9ff;
      height: 54px;
      line-height: 54px;
      th {
        background: #f2f6fc;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #000000;
        text-align: center;
        border: 1px solid #dae9ff;
      }
      td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border: 1px solid #dae9ff;
      }
    }
  }
  .ui-box-tableList-img {
    width: 80px;
    height: 80px;
    background: #d8d8d8;
    display: inline-block;
    vertical-align: top;
  }
  .ui-order-goodsInfo-all {
    margin-top: 24px;
    float: right;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
    margin-left: 15px;

    &.red {
      color: #e86969;
    }
  }
}
</style>
     