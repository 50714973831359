<template>
  <div class="ui-content">
    <div class="ui-box">
      <el-form ref="form" class="ui-box-from-userDetail" label-position="right" :rules="rules" :model="form" label-width="150px">
        <el-form-item label="账号" prop="tbAccount">
          <el-input class="ui-box-from-userDetail-input width645" placeholder="请输入账号" v-model="form.tbAccount" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="tbPhone">
          <el-input class="ui-box-from-userDetail-input width645" placeholder="请输入手机号" v-model="form.tbPhone" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="tbName">
          <el-input class="ui-box-from-userDetail-input width645" placeholder="请输入姓名" v-model="form.tbName" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="tbPassword" v-if="pageType === 'add'">
          <el-input class="ui-box-from-userDetail-input width645" placeholder="请输入密码" v-model="form.tbPassword" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password" v-if="pageType === 'add'">
          <el-input class="ui-box-from-userDetail-input width645" placeholder="请输入密码" v-model="password" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="绑定角色">
          <el-select class="ui-box-from-userDetail-input width645" v-model="form.roleIds" clearable placeholder="请选择角色" multiple :disabled="pageType==='see'">
            <el-option v-for="(item, index) in roleList" :key="index" :label="item.tbName" :value="item.tbId"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定区域">
          <cascaderTree ref="cascaderTreeRef" :props="areaProps" :popoverWidth="645" :leftSpan="4" :cascaderOptions="areaTree" :cascaderValue.sync="areaIds" @change="cascaderChange" :pageType="pageType"></cascaderTree>
        </el-form-item>
        <el-form-item label="绑定医院">
          <el-select class="width645" remote filterable clearable v-model="form.tbHospitalIds" value-key="tbId" multiple placeholder="请输入医院名称后选择" :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in hospitalList" :key="item.tbId" :label="item.tbHospitalName" :value="item.tbId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="ui-box-userDetail-button">
        <el-button class="return" @click="returnList" v-has="pathCode+':return'">返回列表</el-button>
        <el-button type="primary" class="submit" v-has="pathCode+':save'" v-show="pageType!=='see'" @click="save" v-focus @keyup.enter.native="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
const files = require.context('/', false, /\.vue$/);
const suffixList = files.id
  .split(' ')[0]
  .split('/')
  .filter((a, b) => b > 2);
const fileName = 'userDetail';
const controller = 'serviceUser';
const parentName = '用户管理';
import cascaderTree from '@/components/cascaderTree/cascaderTree.vue';
import { mapGetters } from 'vuex';
export default {
  name: `${suffixList.join('')}${fileName}`,
  data () {
    const _this = this;
    return {
      pathCode: `${suffixList.join('')}${fileName}`,
      password: '',
      loading: false,
      defaultMsg: '',
      config: {
        initialFrameWidth: '100%',
        initialFrameHeight: 350
      },
      filePath: ['', ...suffixList, fileName].join('/'),
      fileName: fileName,
      rules: {
        tbAccount: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        tbPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        tbName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        tbPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      },
      areaIds: [],
      popoverWidth: null,
      areaProps: {
        multiple: true,
        value: 'id',
        label: 'areaName',
        children: 'children'
      },
      roleList: [],
      hospitalList: [],
      form: {
        tbAccount: '',
        tbPhone: '',
        tbName: '',
        tbPassword: '',
        userAreaList: [],
        roleIds: []
      }
    };
  },
  computed: {
    isSee () {
      if (Object.keys(this.lastBreadCrumb.length > 0)) {
        if (['查看用户'].includes(this.lastBreadCrumb[this.fileName])) {
          return true;
        }
      }
      return false;
    },
    pageType () {
      if (Object.keys(this.lastBreadCrumb.length > 0)) {
        if (['新增用户'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'add';
        } else if (['修改用户'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'edit';
        } else if (['查看用户'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'see';
        }
      }
      return 'add';
    },
    ...mapGetters(['lastBreadCrumb', 'refreshPage', 'areaTree'])
  },
  watch: {
    $route (to, from) {
      if (to.path === this.filePath && this.refreshPage) {
        this.init();
        this.$store.dispatch('setRefreshPage', false);
      }
    }
  },
  methods: {
    remoteMethod (name, isFirst = false) {
      if (name !== '' || isFirst) {
        this.loading = true
        this.$http.get(`/hospital/findMultiList`, { name: name, tbIds: this.form.tbHospitalIds.join(',') })
          .then(res => {
            this.loading = false
            if (res.code === 1000) {
              this.hospitalList = res.data
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    getRoleList () {
      this.$http.get(`/role/findAll`, {}).then((res) => {
        if (res.code === 1000) {
          this.roleList = res.data;
        }
      });
    },
    clear () {
      this.form = {
        tbAccount: '',
        tbPhone: '',
        tbName: '',
        tbPassword: '',
        userAreaList: [],
        roleIds: []
      };
      this.password = ''
    },
    init () {
      this.type = this.$route.query.type;
      if (this.pageType !== 'add') {
        this.getById(this.$route.query.tbId);
      } else {
        this.clear();
      }
    },
    initForm (form) {
      this.areaIds = form.userAreaList.map(({ tbProvinceId, tbCityId, tbAreaId }) => {
        return [100000, tbProvinceId, tbCityId, tbAreaId]
      })
      return form;
    },
    getById (id) {
      this.$http.get(`/${controller}/get`, { tbId: id }).then((res) => {
        if (res.code === 1000) {
          this.form = this.initForm(res.data);
          if (this.form.tbHospitalIds?.length) {
            this.remoteMethod('', true)
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    returnList () {
      this.closeTab(
        this.filePath,
        parentName,
        this.fileName,
        ['', ...suffixList].join('/'),
        true
      );
    },
    beforeSave (form) {
      return form;
    },
    cascaderChange (val) {
      this.form.userAreaList = []
      if (val.length) {
        const codes = val.map(i => i[i.length - 1])
        const CheckedNodes = this.$refs.cascaderTreeRef.$refs.cascaderRef.getCheckedNodes()
        for (const i of CheckedNodes) {
          if (codes.includes(i.value)) {
            this.form.userAreaList.push({
              tbProvinceId: i.path[1],
              tbProvince: i.pathLabels[1],
              tbCityId: i.path[2],
              tbCity: i.pathLabels[2],
              tbAreaId: i.path[3],
              tbArea: i.pathLabels[3]
            })
          }
        }
      }
    },
    setPhoto (data, filed) {
      if (data[filed]) {
        data[`${filed}Temp`] = data[filed] = data[filed].split(',').map((i) => {
          return {
            name: '',
            url: this.setUrlOss(i)
          };
        });
      } else {
        data[`${filed}Temp`] = data[filed] = [];
      }
    },
    save () {
      if (this.pageType === 'add' && this.form.tbPassword !== this.password) {
        this.$message.error('两次输入的密码不一致')
        return ''
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          form = this.beforeSave(form);
          this.$http.post(`/${controller}/save`, form).then((res) => {
            if (res.code === 1000) {
              this.returnList();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    }
  },
  destroyed () {
  },
  created () {
    if (this.areaTree.length === 0) {
      this.$store.dispatch('getAreaTree', this)
    }
    this.init()
    this.getRoleList()

  },
  mounted () { },
  components: {
    cascaderTree
  }
};
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    overflow-x: auto;
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-userDetail {
      padding-top: 29px;
      .addButton {
        cursor: pointer;
        margin-top: 5px;
        margin-bottom: 24px;
        margin-left: 170px;
        width: 645px;
        height: 32px;
        border-radius: 2px;
        border: 1px dashed rgba(0, 0, 0, 0.25);
        font-size: 14px;
        background-color: rgba(245, 245, 245, 1);
        color: #999999;
        display: block;
        &:focus {
          outline: none;
        }
        .margin11 {
          margin-right: 11px;
        }
      }
      ::v-deep .el-form-item {
        margin-bottom: 24px;
        .ui-box-tableList-img {
          width: 80px;
          height: 80px;
          background: #d8d8d8;
          display: inline-block;
          vertical-align: top;
        }
        &.inline {
          display: inline-block;
          vertical-align: top;
        }
        &.height-auto {
          ::v-deep .el-form-item__content {
            height: auto;
          }
        }

        ::v-deep .el-form-item__label {
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
        }
        ::v-deep .el-form-item__error {
          padding-left: 20px;
        }
        ::v-deep .el-form-item__content {
          height: 100%;
          padding-left: 20px;
          line-height: 32px;
        }
        .ui-box-from-userDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 48px;
        }
        .width645 {
          width: 645px;
        }
        .margin40 {
          margin-right: 40px;
        }
        .width761 {
          width: 761px;
        }

        .ui-box-from-userDetail-input {
          width: 275px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &.width645 {
            width: 645px;
          }
          ::v-deep .el-icon-time,
          ::v-deep .el-icon-arrow-down,
          ::v-deep .el-icon-arrow-up,
          ::v-deep .el-icon-date,
          ::v-deep .el-icon-date,
          ::v-deep .el-range__icon,
          ::v-deep .el-range-separator,
          ::v-deep .el-range-input,
          ::v-deep .el-range__close-icon,
          ::v-deep .el-icon-circle-close,
          ::v-deep .el-icon-arrow-up,
          ::v-deep .el-icon-date,
          ::v-deep .el-input__inner {
            vertical-align: top;
            height: 30px;
            line-height: 30px;
            border: none;
          }
          ::v-deep .el-input__inner::-webkit-outer-spin-button,
          // eslint-disable-next-line no-irregular-whitespace
          ::v-deep .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          ::v-deep .el-input__inner[type="number"] {
            -moz-appearance: textfield;
          }
          ::v-deep .el-input-group__append {
            height: 30px;
            border: none;
            &::-moz-placeholder,
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
            }
          }
          ::v-deep .el-input-group__append {
            padding: 0 15px;
          }
        }
        .ui-box-from-userDetail-button {
          margin-left: 20px;
          vertical-align: top;
          padding: 8px 32px;
          border-radius: 3px;
          &:focus {
            outline: none;
          }
        }
        .ui-box-from-userDetail-img {
          cursor: pointer;
          line-height: 32px;
          margin-right: 8px;
        }
        .ui-box-from-userDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: middle;
          height: 100%;
          margin-right: 30px;
        }
      }
    }
    .ui-box-userDetail-button {
      margin-left: 217px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      .return {
        padding: 6px 25px;
        margin-right: 40px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
      .submit {
        margin-left: 0;
        padding: 6px 25px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-userDetail {
      padding-top: 29px;
      ::v-deep .el-form-item {
        margin-bottom: 24px;
        .el-form-item__label {
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
        }
        .el-form-item__content {
          height: 100%;
          padding-left: 20px;
          line-height: 32px;
        }
        .ui-box-from-userDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 48px;
        }
        .width645 {
          width: 645px;
        }
        .margin40 {
          margin-right: 40px;
        }
        .el-form-item__error {
          padding-left: 20px;
        }
        .ui-box-from-userDetail-input {
          width: 275px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &.width645 {
            width: 645px;
          }
          .el-icon-time,
          .el-icon-arrow-down,
          .el-icon-arrow-up,
          .el-icon-date,
          .el-icon-date,
          .el-range__icon,
          .el-range-separator,
          .el-range-input,
          .el-icon-circle-close,
          .el-icon-arrow-up,
          .el-icon-date,
          .el-input__inner {
            vertical-align: top;
            height: 30px;
            line-height: 30px;
            border: none;
          }
          .el-input__inner::-webkit-outer-spin-button,
          // eslint-disable-next-line no-irregular-whitespace
          .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          .el-input__inner[type="number"] {
            -moz-appearance: textfield;
          }
          .el-input-group__append {
            height: 30px;
            border: none;
            &::-moz-placeholder,
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
            }
          }
          .el-input-group__append {
            padding: 0 15px;
          }
        }
        .ui-box-from-userDetail-button {
          margin-left: 20px;
          vertical-align: top;
          padding: 8px 32px;
          border-radius: 3px;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .ui-box-userDetail-button {
      margin-left: 217px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      .return {
        padding: 6px 25px;
        margin-right: 40px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
      .submit {
        margin-left: 0;
        padding: 6px 25px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>