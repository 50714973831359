<template>
  <div class="ui-content">
    <div class="ui-box">
      <el-row type="flex" justify="space-between">
        <el-col :span="8">
          <div class="ui-top">
            <span class="ui-top-label">订单编号</span>
            <span class="ui-top-value">{{form.orderCode}}</span>
            <span class="ui-top-button" @click="openTab('订单详情', 'orderDetail', '/statistic/orderList/orderDetail' ,{tbId:form.tbOrderId,orderType:1})">查看订单</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ui-top" style="justify-content: center;">
            <span class="ui-top-label">处方单号</span>
            <span class="ui-top-value">{{form.perscriptionNumber}}</span>
            <span class="ui-top-button" @click="dialogVisible=true">处方单</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ui-top" style="justify-content: flex-end;">
            <span class="ui-top-label">申请时间</span>
            <span class="ui-top-value">{{form.createTime}}</span>
          </div>
        </el-col>
      </el-row>
      <div class="ui-prescriptionDetail-info">
        <div class="ui-prescriptionDetail-info-title">用药人信息</div>
        <table class="ui-prescriptionDetail-info-table">
          <tr>
            <th>姓名</th>
            <th>性别</th>
            <th>年龄</th>
            <th>体重</th>
          </tr>
          <tr>
            <td>{{form.patientName}}</td>
            <td>{{form.patientSex===1?'男':form.patientSex===2?'女':''}}</td>
            <td>{{form.patientAge&&`${form.patientAge}岁`||''}}</td>
            <td>{{form.patientWeight&&`${form.patientWeight}kg`||''}}</td>
          </tr>
          <tr>
            <th>手机号码</th>
            <th>身份证号</th>
            <th>出生日期</th>
            <th>地址</th>
          </tr>
          <tr>
            <td>{{form.patientPhone}}</td>
            <td>{{form.patientIdCard}}</td>
            <td>{{new Date(form.patientBirthday).format('yyyy-MM-dd')}}</td>
            <td>{{form.patientAddress}}</td>
          </tr>
        </table>
      </div>

      <div class="ui-prescriptionDetail-info">
        <div class="ui-prescriptionDetail-info-title">健康信息</div>
        <table class="ui-prescriptionDetail-info-table">
          <tr>
            <th>有无过往病史描述</th>
            <th>有无过敏史描述</th>
            <th>有无家庭病史描述</th>
          </tr>
          <tr>
            <td>{{form.pmh?form.pmh:'无'}}</td>
            <td>{{form.amh?form.amh:'无'}}</td>
            <td>{{form.fmh?form.fmh:'无'}}</td>
          </tr>
          <tr>
            <th>有无妊娠哺乳描述</th>
            <th>有无肝功能异常描述</th>
            <th>有无肾功能异常描述</th>
          </tr>
          <tr>
            <td>{{form.nurseDesc?form.nurseDesc:'无'}}</td>
            <td>{{form.liverDesc?form.liverDesc:'无'}}</td>
            <td>{{form.renalDesc?form.renalDesc:'无'}}</td>
          </tr>
        </table>
      </div>

      <div class="ui-prescriptionDetail-info">
        <div class="ui-prescriptionDetail-info-title">问诊信息</div>
        <table class="ui-prescriptionDetail-info-table">
          <tr>
            <th class="width400">此次问诊疾病信息</th>
            <td>{{form.detailDiseaseList.map(i=>i.diseaseName).join(',')}}</td>
          </tr>
          <tr>
            <th class="width400">诊断级别</th>
            <td>{{diagnosticLevelMap[form.diagnosticLevel]}}</td>
          </tr>
          <tr>
            <th class="width400">既往用药情况</th>
            <td>{{drugUsageMap[form.drugUsage]}}</td>
          </tr>
          <tr>
            <th class="width400">疾病描述</th>
            <td>{{form.diagnoseDescription}}</td>
          </tr>
        </table>
      </div>

      <div class="ui-prescriptionDetail-info">
        <div class="ui-prescriptionDetail-info-title">处方信息</div>
        <table class="ui-prescriptionDetail-info-table">
          <tr>
            <th>药店</th>
            <th>开方医师</th>
            <th>审核药师</th>
          </tr>
          <tr>
            <td>{{form.companyName}}</td>
            <td>{{form.doctorNmae}}</td>
            <td>{{form.checkDoctorName}}</td>
          </tr>
          <tr>
            <th>复核药师</th>
            <th>调配药师</th>
            <th></th>
          </tr>
          <tr>
            <td>{{form.reviewDoctorName}}</td>
            <td>{{form.arrangeDoctoreName}}</td>
            <td></td>
          </tr>
        </table>
      </div>

      <div class="ui-prescriptionDetail-info">
        <div class="ui-prescriptionDetail-info-title">商品信息</div>
        <table class="ui-prescriptionDetail-info-table">
          <tr>
            <th>药品名称</th>
            <th>规格</th>
            <th>数量</th>
            <th>单位</th>
          </tr>
          <tr v-for="(item,index) in form.detailDrugList" :key="index">
            <td>{{item.commonName}}</td>
            <td>{{item.spec}}</td>
            <td>{{item.count}}</td>
            <td>{{item.countUnit}}</td>
          </tr>
        </table>
      </div>

    </div>
    <el-dialog title="处方单" top="10vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" :before-close="dialogBeforeClose">
      <img class="" v-for="url in form.prescriptionUrl" :key="url" height="100%" style="object-fit:scale-down;" :src="setUrlOss(url)" alt="" @error="errorImg">
      <span slot="footer" class="dialog-footer">
        <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
        <el-button class="ui-box-submit" type="primary" @click="dialogBeforeClose">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'statisticorderListprescriptionDetail',
  data () {
    return {
      dialogVisible: false,
      drugUsageMap: {
        1: '药物用完需继续购买完成疗程',
        2: '医生建议使用此药'
      },
      diagnosticLevelMap: {
        1: '普通患病并已确诊',
        2: '慢性病并确诊',
        3: '尚未确诊'
      },
      form: {
        detailDiseaseList: []
      }

    }
  },
  computed: {
    ...mapGetters([
      'lastBreadCrumb',
      'refreshPage'
    ])
  },
  methods: {
    dialogBeforeClose () {
      this.dialogVisible = false
    },
    getById () {
      this.$http.get('/prescriptioninfo/get', { tbId: this.$route.query.tbId })
        .then(res => {
          if (res.code === 1000) {
            if (res.data.detailDiseaseList) {
              res.data.detailDiseaseList = JSON.parse(res.data.detailDiseaseList)
            }
            if (res.data.detailDrugList) {
              res.data.detailDrugList = JSON.parse(res.data.detailDrugList)
            }
            res.data.prescriptionUrl = res.data.prescriptionUrl.split(',')
            this.form = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    }
  },
  watch: {
    $route (to, from) {
      if (to.path === '/statistic/orderList/prescriptionDetail' && this.refreshPage) {
        this.getById()
        this.$store.dispatch('setRefreshPage', false)
      }
    }
  },
  components: {

  },
  created () {
    this.getById()
  },
  mounted () { }

}
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    .ui-top {
      margin: 0 24px;
      padding: 24px 0 0;
      display: flex;
      align-items: center;
      .ui-top-label {
        font-size: 17px;
        font-weight: 700;
        color: #000000;
        margin-right: 12%;
      }
      .ui-top-value {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        margin-right: 10%;
      }
      .ui-top-button {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        padding: 5px 16px;
        background: #f7b500;
        border-radius: 2px;
      }
    }
    .ui-prescriptionDetail-info {
      margin: 40px 24px 40px;
      width: calc(100% - 48px);
      height: auto;
      .ui-prescriptionDetail-info-title {
        margin-bottom: 10px;
        width: 180px;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 28px;
      }
      .ui-prescriptionDetail-info-table {
        table-layout: fixed;
        text-align: center;
        width: 100%;
        border: 1px solid #dae9ff;
        border-collapse: collapse;
        tr {
          border: 1px solid #dae9ff;
          height: 54px;
          line-height: 54px;
          th {
            background: #f2f6fc;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            text-align: center;
            border: 1px solid #dae9ff;
            &.width400 {
              width: 400px;
            }
          }
          td {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            border: 1px solid #dae9ff;
          }
        }
      }
    }
  }
}
::v-deep .el-dialog {
  max-width: 1140px;
  ::v-deep .el-dialog__body {
    overflow: auto;
    max-height: 60vh;
  }
}
::v-deep .el-dialog {
  max-width: 1140px;
  .el-dialog__body {
    overflow: auto;
    max-height: 75vh;
  }
}
</style>