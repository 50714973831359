<template>
  <div class="ui-content">
    <div class="ui-seachBox">
      <el-row class="row-bg">
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>输入搜索</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-input class="ui-seachBox-input" v-model="seach.tbOrderCode" placeholder="订单编号/关键字"></el-input>
        </el-col>
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>收货人</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-input class="ui-seachBox-input" v-model="seach.tbLinkMan" placeholder="收货人姓名/手机号码"></el-input>
        </el-col>
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>提交时间</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-date-picker class="ui-seachBox-input" value-format="yyyy-MM-dd HH:mm:ss" v-model="time" type="datetimerange" range-separator="至" start-placeholder="" end-placeholder="">
          </el-date-picker>
          <!-- <el-date-picker class="ui-seachBox-input" v-model="seach.createStartTime" type="date" placeholder="选择日期"></el-date-picker> -->
        </el-col>
      </el-row>
      <el-row class="row-bg">
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>订单状态</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select class="ui-seachBox-input" clearable v-model="seach.tbState" placeholder="请选择">
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>是否指派</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select clearable class="ui-seachBox-input" v-model="seach.distributionStatus" placeholder="请选择">
            <el-option label="是" :value="2"> </el-option>
            <el-option label="否" :value="3"></el-option>
            <el-option label="无" :value="1"></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>是否租赁</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select clearable class="ui-seachBox-input" v-model="seach.tbIsBuyHire" placeholder="请选择">
            <el-option label="是" :value="1"> </el-option>
            <el-option label="否" :value="0"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="row-bg">
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>是否自提</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select clearable class="ui-seachBox-input" v-model="seach.tbIsExtract" placeholder="请选择">
            <el-option label="是" :value="1"> </el-option>
            <el-option label="否" :value="0"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>租赁状态</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select clearable class="ui-seachBox-input" v-model="seach.tbIsSettlement" placeholder="请选择">
            <el-option label="租赁中" :value="0"></el-option>
            <el-option label="到期结算" :value="1"></el-option>
            <el-option label="超期结算" :value="2"> </el-option>
            <el-option label="超额结算" :value="3"></el-option>
            <el-option label="提前结算" :value="4"></el-option>
          </el-select>
        </el-col>
        <el-button class="ui-seachBox-submit" type="primary" @click="getList">查 询</el-button>
        <el-button class="ui-seachBox-reset" @click="reset">重 置</el-button>
      </el-row>
    </div>
    <div class="ui-tableBox">
      <div class="ui-tableBox-bar">
        <p class="ui-tableBox-title">数据列表</p>
        <el-button class="ui-tableBox-button export" @click="exportExcel">导出列表</el-button>
      </div>
      <div class="ui-tableList">
        <el-table v-loading="loading" :data="tableList" :max-height="tableHeight" border style="width: 100%" :header-cell-style="{'font-size': '14px','font-family': 'Microsoft YaHei','font-weight': 500,color: '#000000',background: '#FAFAFA'}" @selection-change="handleSelectionChange">
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column align="center" type="index" label="排序" width="60px">
          </el-table-column>
          <el-table-column align="center" prop="tbOrderCode" label="订单编号">
          </el-table-column>
          <el-table-column align="center" prop="tbOrderGoodsName" label="商品名称" show-overflow-tooltip>
            <template slot-scope="{row}">
              <div v-if="row.isPrescription===1" class="labelBox">处方</div>
              {{row.tbOrderGoodsName}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="tbNumber" label="数量">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="提交时间">
          </el-table-column>
          <el-table-column align="center" prop="tbLinkMan" label="收货人">
          </el-table-column>-
          <el-table-column align="center" prop="tbShopName" label="下单自提点">
          </el-table-column>
          <el-table-column align="center" prop="tbPrice" label="订单金额(元)">
          </el-table-column>
          <el-table-column align="center" label="支付方式">
            <template slot-scope="{row}">
              {{payTypeMap[row.tbPayType]}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="tbDoctorName" label="医生姓名">
          </el-table-column>
          <el-table-column align="center" label="助理姓名">
            <template slot-scope="{row}">
              {{row.distributionStatus}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单状态">
            <template slot-scope="{row}">
              {{getStatusMap(row)}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否回复" v-if="seach.orderType == 6">
            <template slot-scope="{row}">
              {{row.tb_state===1?'未回复':'已回复'}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否租赁">
            <template slot-scope="{row}">
              {{row.tbIsBuyHire===1?'是':'否'}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否自提">
            <template slot-scope="{row}">
              {{row.tbIsExtract===1?'是':'否'}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="租赁状态">
            <template slot-scope="{row}">
              {{row.tbIsBuyHire===1?settlementMap[row.tbIsSettlement]:'无'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="name" label="操作">
            <template v-if="row.orderType===1" slot-scope="{row}">
              <el-button class="ui-tableList-button" size="mini" @click="openTab('订单详情', 'orderDetail', '/statistic/orderList/orderDetail' ,{tbId:row.tbId,orderType:row.orderType})">查看订单</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination class="ui-papagination" @size-change="page.pagingSizeChange" @current-change="page.pagingCurrentChange" :total="page.total" :pageSizes="page.pageSizes" :pageSize="page.pageSize" :currentPage="page.currentPage"></pagination>
    </div>
    <orderTracking :data.sync="trackData" :dialogVisible.sync="dialogVisible"></orderTracking>
  </div>
</template>

<script>
import orderTracking from '@/components/order/orderDetail/orderTracking'
import { debounceUtils } from '@/utils/common.js'
import { mapGetters } from 'vuex'
export default {
  name: 'statisticorderList',
  data () {
    const _this = this
    return {
      dialogVisible: false,
      trackData: [],
      loading: false,
      selectRow: [],
      tableHeight: null,
      selectValue: '',
      page: {
        currentPage: 1, //当前页
        pageSize: 10, //每页记录数
        total: 10,
        pageSizes: [10, 20, 50, 100],
        pagingSizeChange (value) {
          _this.page.currentPage = 1
          _this.page.pageSize = value
          _this.getList()
        },
        pagingCurrentChange (value) {
          _this.page.currentPage = value
          _this.getList()
        }
      },
      tableList: [],
      distributionStatusMap: {
        '1': '无',
        '2': '是',
        '3': '否'
      },
      options: [
        {
          id: '1',
          name: '批量发货'
        },
        {
          id: '2',
          name: '关闭订单'
        }
      ],
      time: [],
      seach: {
        name: '',
        tbOrderCode: '',
        tbLinkMan: '',
        createStartTime: '',
        createEndTime: '',
        tbState: '',
        orderType: '1',
        distributionStatus: '',
        tbIsBuyHire: '',
        tbIsExtract: '',
        tbIsSettlement: ''
      },
      settlementList: [
        {
          id: '0',
          name: '租赁中'
        },
        {
          id: '1',
          name: '到期结算'
        },
        {
          id: '2',
          name: '超期结算'
        },
        {
          id: '3',
          name: '超额结算'
        },
        {
          id: '4',
          name: '提前结算'
        }
      ],
      statusList: [
        {
          id: '1',
          name: '待付款'
        },
        {
          id: '2',
          name: '待发货'
        },
        {
          id: '3',
          name: '已发货'
        },
        {
          id: '4',
          name: '已完成'
        },
        {
          id: '5',
          name: '已评价'
        },
        {
          id: '6',
          name: '已关闭'
        },
        {
          id: '7',
          name: '交易成功'
        }
      ],
      consultingStatusList: [
        {
          id: '1',
          name: '待付款'
        },
        {
          id: '2',
          name: '已付款'
        },
        {
          id: '3',
          name: '待完成'
        },
        {
          id: '4',
          name: '已完成'
        },
        {
          id: '5',
          name: '已退款'
        },
        {
          id: '6',
          name: '已关闭'
        },
        {
          id: '7',
          name: '交易成功'
        }
      ],
      payTypeList: [
        {
          id: '0',
          name: '未支付'
        },
        {
          id: '1',
          name: '微信'
        },
        {
          id: '2',
          name: '支付宝'
        },
        {
          id: '3',
          name: '苹果支付'
        },
        {
          id: '4',
          name: '虚拟币支付'
        },
        {
          id: '5',
          name: '已支付(0元支付)'
        },
        {
          id: '6',
          name: '微信支付'
        }
      ],
      typeList: [
        {
          id: '1',
          name: '订单商品表'
        },
        {
          id: '2',
          name: '音乐订单表'
        },
        {
          id: '3',
          name: '学堂订单表'
        },
        {
          id: '4',
          name: '孕动订单表'
        },
        // {
        //   id: '5',
        //   name: '医疗包'
        // },
        {
          id: '6',
          name: '图文咨询'
        },
        {
          id: '7',
          name: '电话咨询'
        },
        {
          id: '8',
          name: '热门问题'
        },
        {
          id: '9',
          name: '虚拟币订单'
        },
        {
          id: '10',
          name: '圈子订单'
        },
        {
          id: '11',
          name: '极速咨询'
        }
      ]
    }
  },
  computed: {
    settlementMap () {
      return this.settlementList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    consultingStatusMap () {
      return this.consultingStatusList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    statusMap () {
      const tbPayType = this.form && this.form.tbPayType
      return this.statusList.reduce((res, { id, name }) => {
        res[id] = name
        if (id == 7 && tbPayType == 7) {
          res[id] = '会员赠送'
        }
        return res
      }, {})
    },
    payTypeMap () {
      return this.payTypeList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    typeMap () {
      return this.typeList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    ...mapGetters([
      'lastBreadCrumb',
      'refreshPage'
    ])
  },
  watch: {
    $route (to, from) {
      if (to.path === '/statistic/orderList' && this.refreshPage) {
        this.getList()
        this.$store.dispatch('setRefreshPage', false)
      }
    }
  },
  methods: {
    getStatusMap (row) {
      if ([6, 7].includes(this.seach.orderType)) {
        return this.consultingStatusMap[row.tbPayState]
      } else {
        if (row.tbIsExtract && row.tbState == 3) {
          return '待自提'
        } else {
          return this.statusMap[row.tbState]
        }
      }
    },
    getOrderDetailsTrack (code) {
      this.$http.get('/order/getOrderDetailsTrack', { orderCode: code })
        .then(res => {
          if (res.code === 1000) {
            this.trackData = res.data.map(i => {
              i.tbListMinFigure = i.tbListMinFigure ? i.tbListMinFigure.split(',') : []
              i.traces = i.traces.reverse()
              return i
            })
            this.dialogVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
    },
    del ({ tbId: id, orderType }) {
      this.$http.post('/order/deleteOrders', { id, orderType })
        .then(res => {
          if (res.code === 1000) {
            this.$message.success('删除成功')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    reset () {
      this.seach = {
        name: '',
        tbOrderCode: '',
        tbLinkMan: '',
        createStartTime: '',
        createEndTime: '',
        tbState: '',
        orderType: '1',
        distributionStatus: '',
        tbIsBuyHire: '',
        tbIsExtract: '',
        tbIsSettlement: ''
      }
      this.time = []
    },
    see () { },
    orderSelect (val) {
      if (this.selectRow.length === 0) {
        this.$message.error('至少勾选一项列表')
      } else {
        if (val === '1') {
          if (this.selectRow.some(i => i.tbState !== 2 && i.tbType === 2)) {
            this.$message.error('存在不能发货的勾选项,请重新勾选')
          } else {
            const ids = this.selectRow.map(i => i.tbId).join(',')
            const orderTypes = this.selectRow.map(i => i.orderType).join(',')
            this.msgboxMixin(() => {
              this.openTab('批量发货', 'bulkDelivery', '/order/orderList/bulkDelivery', { tbId: ids, orderType: orderTypes })
            }, '确定要批量发货勾选的订单吗?')
          }
        } else {
          this.msgboxMixin(() => {
            this.closeOrders(this.selectRow.map(({ orderType, tbId }) => {
              return { tbId: tbId, orderType: orderType }
            }))
          }, '确定要批量关闭勾选的订单吗?')
        }
      }
      this.selectValue = ''
    },
    closeOrders (ids) {
      this.$http.post('/order/batchCloseOrders', ids)
        .then(res => {
          if (res.code === 1000) {
            this.$message.success('关闭成功')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    handleSelectionChange (val) {
      this.selectRow = val
    },
    exportExcel () {
      if (this.time && this.time.length === 2) {
        this.seach.createStartTime = this.time[0]
        this.seach.createEndTime = this.time[1]
      } else {
        delete this.seach.createStartTime
        delete this.seach.createEndTime
      }
      this.seach.page = this.page.currentPage
      this.seach.size = this.page.pageSize
      this.$http.export('/order/exportOrderByStaff', this.seach)
        .then(res => {

        })
    },
    getList: debounceUtils(function () {
      this.loading = true
      if (this.time && this.time.length === 2) {
        this.seach.createStartTime = this.time[0]
        this.seach.createEndTime = this.time[1]
      } else {
        delete this.seach.createStartTime
        delete this.seach.createEndTime
      }
      this.seach.page = this.page.currentPage
      this.seach.size = this.page.pageSize
      this.$http.get('/order/findListByStaffId', this.seach)
        .then(res => {
          this.loading = false
          if (res.code === 1000) {
            this.tableList = res.data.list
            this.page.total = res.data.total
          } else {
            this.$message.error(res.message)
          }
        })
    }, 500)
  },
  destroyed () {
    this.destroyResizeListen(556)
  },
  created () {
    this.addResizeListen(556)
  },
  mounted () {
    this.getList()
  },
  components: {
    orderTracking
  }
}
</script>

<style lang="scss" scoped>
>>> .ui-seachBox-input .el-input__inner {
  border: none;
}
>>> .ui-tableBox-button .el-input__inner {
  border: none;
  &::-webkit-input-placeholder {
    color: #606266;
  }
  &::-moz-placeholder {
    color: #606266;
  }
  &::-ms-input-placeholder {
    color: #606266;
  }
}
.ui-content {
  width: 100%;
  height: 100%;
  .ui-seachBox {
    margin: 0 24px 24px;
    width: calc(100% - 48px);
    height: 192px;
    background: #ffffff;
    border-radius: 2px;
    .row-bg {
      padding: 24px 24px 0;
      .ui-seachBox-label {
        display: inline-block;
        text-align: right;
        width: 100px;
        height: 32px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        vertical-align: middle;
      }
      .ui-seachBox-Symbol {
        margin-left: 3px;
        margin-right: 5px;
      }
      .ui-seachBox-input {
        display: inline-block;
        width: calc(100% - 100px);
        height: 32px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
        ::v-deep .el-icon-circle-close,
        ::v-deep .el-icon-arrow-up,
        ::v-deep .el-icon-date,
        ::v-deep .el-range__icon,
        ::v-deep .el-range-separator,
        ::v-deep .el-range-input,
        ::v-deep .el-range__close-icon,
        ::v-deep .el-input__inner {
          vertical-align: top;
          height: 30px;
          line-height: 30px;
          border: none;
        }
      }
    }
    .ui-seachBox-reset {
      margin: 0 0 0 8px;
      float: right;
      border-radius: 2px;
      padding: 9px 15px;
      &:focus {
        outline: none;
      }
    }
    .ui-seachBox-submit {
      padding: 9px 15px;
      margin: 0 0 0 8px;
      float: right;
      border-radius: 2px;
      &:focus {
        outline: none;
      }
    }
  }
  .ui-tableBox {
    margin: 0 24px;
    width: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px;
    height: calc(100% - 240px);
    position: relative;
    .ui-tableBox-bar {
      position: relative;
      margin: 0 24px;
      height: 64px;
      .ui-tableBox-title {
        height: 24px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
        display: inline-block;
        margin-top: 20px;
      }
      .ui-tableBox-button {
        width: 108px;
        position: relative;
        float: right;
        top: 16px;
        margin-left: 24px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        &.export {
          padding: 9px 16px;
        }
        ::v-deep .el-icon-arrow-up,
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
          border: none;
          &::-webkit-input-placeholder {
            color: #606266;
          }
          &::-moz-placeholder {
            color: #606266;
          }
          &::-ms-input-placeholder {
            color: #606266;
          }
        }
        &:focus {
          outline: none;
        }
      }
    }
    .ui-tableList {
      margin: 8px 24px 0 24px;
      ::v-deep .el-table__body-wrapper {
        height: auto !important;
      }
      .ui-tableList-button {
        padding: 9px 15px;
        outline: none;
      }
      .labelBox {
        width: 30px;
        height: 20px;
        background: #f7b500;
        border-radius: 2px;
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        display: inline-block;
      }
    }
  }
}
</style>