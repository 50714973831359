<template>
  <div class="ui-content">
    <div class="ui-seachBox">
      <el-row class="row-bg">
        <el-col :span="8">
          <p class="ui-seachBox-label">
            <span>姓名</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-input class="ui-seachBox-input" v-model="seach.tbName" placeholder="请输入"></el-input>
        </el-col>
        <el-col :span="8">
          <p class="ui-seachBox-label">
            <span>手机号</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-input class="ui-seachBox-input" v-model="seach.tbPhone" placeholder="请输入"></el-input>
        </el-col>
        <el-col :span="8">
          <p class="ui-seachBox-label">
            <span>阶段</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select class="ui-seachBox-input" clearable v-model="seach.type" placeholder="请选择">
            <el-option v-for="item in phaseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="row-bg">
        <el-col :span="8">
          <p class="ui-seachBox-label">
            <span>状态</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select class="ui-seachBox-input" clearable v-model="seach.status" placeholder="请选择">
            <el-option label="备孕" value="备孕"></el-option>
            <el-option label="怀孕" value="怀孕中"></el-option>
            <el-option label="产后" value="产后"></el-option>
          </el-select>
        </el-col>
        <el-button class="ui-seachBox-submit" type="primary" @click="getList">查 询</el-button>
        <el-button class="ui-seachBox-reset" @click="reset">重 置</el-button>
      </el-row>
    </div>
    <div class="ui-tableBox">
      <div class="ui-tableBox-bar">
        <p class="ui-tableBox-title">数据列表</p>
      </div>
      <div class="ui-tableList">
        <el-table v-loading="loading" :data="tableList" :max-height="tableHeight" :default-sort="{prop: 'tbSort', order: 'ascending'}" border style="width: 100%" :header-cell-style="{background: '#FAFAFA'}">
          <el-table-column align="center" type="index" width="68px" label="序号">
          </el-table-column>
          <el-table-column align="center" prop="nickname" label="昵称">
          </el-table-column>
          <el-table-column align="center" prop="username" label="姓名">
          </el-table-column>
          <el-table-column align="center" prop="phone" label="手机">
          </el-table-column>
          <el-table-column align="center" prop="tbHomeLocation" label="归属地">
          </el-table-column>
          <el-table-column align="center" label="专属医生">
            <template slot-scope="{row}">
              {{row.userDoctor.map(i=>i&&i.tbName||'').join(',')}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="currentGeneration" label="阶段">
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态">
          </el-table-column>
          <!-- <el-table-column align="center" label="阶段">
            <template slot-scope="{row}">
              {{row.currentGeneration===1?'已怀孕':'已出生'}}
            </template>
          </el-table-column> -->
          <el-table-column align="center" prop="createtime" label="注册日期">
            <template slot-scope="{row}">
              {{row.createtime&&new Date(row.createtime).format('yyyy-MM-dd')}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="prepareProduction" label="预产期">
            <template slot-scope="{row}">
              {{row.prepareProduction&&new Date(row.prepareProduction).format('yyyy-MM-dd')}}
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="referrerName" label="推荐人">
          </el-table-column> -->
          <el-table-column align="center" width="100" prop="name" label="操作" min-width="13.2%">
            <template slot-scope="{row}">
              <el-link class="ui-tableList-link" @click="openTab('查看孕妈/宝妈',detailName,`${filePath}/${detailName}`,{tbId:row.id})">查看</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination class="ui-papagination" @size-change="page.pagingSizeChange" @current-change="page.pagingCurrentChange" :total="page.total" :pageSizes="page.pageSizes" :pageSize="page.pageSize" :currentPage="page.currentPage"></pagination>
    </div>
  </div>
</template>
<script>

const files = require.context('/', false, /\.vue$/)
const suffixList = files.id.split(' ')[0].split('/').filter((a, b) => b > 2)
const fileName = 'pregnant'
const controller = 'user'
import { debounceUtils } from '@/utils/common.js'
import { mapGetters } from 'vuex'
export default {
  name: `${suffixList.join('')}${fileName}`,
  data () {
    const _this = this
    return {
      phaseList: [
        { id: '预产期', name: '预产期' },
        { id: '月子期', name: '月子期' },
        { id: '新生儿', name: '新生儿' },
        { id: '婴幼儿6个月-1岁', name: '婴幼儿6个月-1岁' },
        { id: '婴幼儿2岁', name: '婴幼儿2岁' },
        { id: '婴幼儿3岁', name: '婴幼儿3岁' },
        { id: '儿童', name: '儿童' },
        { id: '无', name: '无' }
      ],
      filePath: ['', ...suffixList, fileName].join('/'),
      fileName: fileName,
      detailName: `${fileName}Detail`,
      loading: true,
      tableHeight: null,
      page: {
        currentPage: 1, //当前页
        pageSize: 10, //每页记录数
        total: 10,
        pageSizes: [10, 20, 50, 100],
        pagingSizeChange (value) {
          _this.page.currentPage = 1
          _this.page.pageSize = value
          _this.getList()
        },
        pagingCurrentChange (value) {
          _this.page.currentPage = value
          _this.getList()
        }
      },
      tableList: [],
      seach: {
        tbName: '',
        tbPhone: '',
        type: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'lastBreadCrumb',
      'refreshPage'
    ])
  },
  watch: {
    $route (to, from) {
      if (to.path === this.filePath && this.refreshPage) {
        this.getList()
        this.$store.dispatch('setRefreshPage', false)
      }
    }
  },
  methods: {
    del (id) {
      this.msgboxMixin(() => {
        this.$http.post(`/${controller}/delete`, [{ 'tbId': id }])
          .then(res => {
            if (res.code === 1000) {
              this.getList()
            } else {
              this.$message.error(res.message)
            }
          })
      })
    },
    reset () {
      this.seach = {
        tbName: '',
        tbPhone: '',
        type: ''
      }
    },
    getList: debounceUtils(function () {
      this.loading = true
      this.seach.page = this.page.currentPage
      this.seach.size = this.page.pageSize
      this.$http.get(`/${controller}/findListByStaffId`, this.seach)
        .then(res => {
          this.loading = false
          if (res.code === 1000) {
            this.tableList = res.data.list
            this.page.total = res.data.total
          } else {
            this.$message.error(res.message)
          }
        })
    }, 500)
  },
  destroyed () {
    this.destroyResizeListen(500)
  },
  created () {
    this.addResizeListen(500)

  },
  mounted () {
    this.getList()
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-seachBox {
    margin: 0 24px 24px;
    width: calc(100% - 48px);
    height: 136px;
    background: #ffffff;
    border-radius: 2px;
    .row-bg {
      padding: 24px 24px 0;
      .ui-seachBox-label {
        display: inline-block;
        text-align: right;
        width: 100px;
        height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        vertical-align: top;
      }
      .ui-seachBox-label2 {
        display: inline-block;
        text-align: right;
        height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        vertical-align: top;
        margin-right: 10px;
      }
      .ui-seachBox-box-switch {
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .ui-seachBox-Symbol {
        margin-left: 3px;
        margin-right: 5px;
      }
      .ui-seachBox-input {
        display: inline-block;
        width: calc(100% - 100px);
        height: 32px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
        ::v-deep .el-icon-circle-close,
        ::v-deep .el-icon-arrow-up,
        ::v-deep .el-icon-date,
        ::v-deep .el-range__icon,
        ::v-deep .el-range-separator,
        ::v-deep .el-range-input,
        ::v-deep .el-range__close-icon,
        ::v-deep .el-input__inner {
          vertical-align: top;
          height: 30px;
          line-height: 30px;
          border: none;
        }
      }
      .ui-seachBox-box-label {
        display: inline-block;
        width: calc(100% - 100px);
        height: 32px;
        line-height: 32px;
      }
    }
    .ui-seachBox-reset {
      margin: 0 0 0 8px;
      float: right;
      border-radius: 2px;
      padding: 9px 15px;
      &:focus {
        outline: none;
      }
    }
    .ui-seachBox-submit {
      padding: 9px 15px;
      margin: 0 0 0 8px;
      float: right;
      border-radius: 2px;
      &:focus {
        outline: none;
      }
    }
  }
  .ui-tableBox {
    margin: 0 24px;
    width: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px;
    height: calc(100% - 184px);
    position: relative;
    .ui-tableBox-bar {
      position: relative;
      margin: 0 24px;
      height: 64px;
      .ui-tableBox-title {
        height: 24px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
        display: inline-block;
        margin-top: 20px;
      }
      .ui-tableBox-button {
        width: 108px;
        position: relative;
        float: right;
        top: 16px;
        margin-left: 24px;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        &.export {
          padding: 8px 15px;
          width: auto;
        }
        ::v-deep .el-icon-arrow-up,
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
          border: none;
          &::-webkit-input-placeholder {
            color: #606266;
          }
          &::-moz-placeholder {
            color: #606266;
          }
          &::-ms-input-placeholder {
            color: #606266;
          }
        }
        &:focus {
          outline: none;
        }
      }
    }
    .ui-tableList {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      margin: 8px 24px 0 24px;
      .ui-box-tableList-img {
        width: 80px;
        height: 80px;
        background: #d8d8d8;
        display: inline-block;
        vertical-align: top;
      }
      .ui-box-tableList-label {
        margin: 0 5px 10px;
      }
      .ui-box-tableList-editImg {
        width: 43px;
        cursor: pointer;
        height: 43px;
      }
      .ui-box-tableList-tempSaveImg {
        width: 41px;
        height: 41px;
        position: absolute;
        left: -2px;
        top: -3px;
      }
      ::v-deep .el-table__body-wrapper {
        height: auto !important;
      }
      .ui-tableList-button {
        padding: 9px 17px;
        outline: none;
      }
      .ui-tableList-link.is-underline:hover:after {
        border: none;
      }
    }
  }
}
</style>