<template>
  <div class="ui-box-item">
    <div class="itemInfo">
      <span class="item-label">圈子信息</span>
    </div>
    <div class="itemInfo-detail">
      <el-row>
        <!-- <el-col :span="12"> -->
        <el-form-item label="粉丝上方title" prop="tbFansTitle">
          <el-input class="width635" v-model="form.tbFansTitle" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="圈主简介" prop="tbCircleMasterIntroduce">
          <el-input class="width635" v-model="form.tbCircleMasterIntroduce" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="圈子简介" prop="tbCircleIntroduce">
          <el-input class="width635" v-model="form.tbCircleIntroduce" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="圈子适用人群" prop="tbCirclePeople">
          <el-input class="width635" v-model="form.tbCirclePeople" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <!-- </el-col> -->
        <!-- <el-col :span="12"> -->
        <el-form-item label="更新速度" prop="tbCircleUpdateSpeed">
          <el-input class="width635" v-model="form.tbCircleUpdateSpeed" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="能获得什么" prop="tbCircleGet">
          <el-input class="width635" v-model="form.tbCircleGet" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="风险提示" prop="tbCircleWarning">
          <el-input class="width635" v-model="form.tbCircleWarning" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <!-- </el-col> -->
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'circleInfo',
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          'tbFansTitle': '',
          'tbCircleMasterIntroduce': '',
          'tbCircleIntroduce': '',
          'tbCirclePeople': '',
          'tbCircleUpdateSpeed': '',
          'tbCircleGet': '',
          'tbCircleWarning': ''
        }
      }
    },
    isSee: Boolean,
    pageType: String,
    status: Number
  },
  data () {
    return {
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.ui-box-item {
  display: flex;
  margin: 11px 0 10px 0;
  .itemInfo {
    flex: 1;
    display: flex;
    margin: 0 10px 0 24px;
    width: 166px;
    background-color: whitesmoke;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  .itemInfo-detail {
    display: inline-block;
    margin: 0 24px 0 0;
    width: calc(100% - 224px);
    background-color: whitesmoke;
    .ui-box-from-practiceInfo-img {
      cursor: pointer;
      line-height: 32px;
      margin-right: 8px;
    }
    .ui-box-from-practiceInfo-text {
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-right: 30px;
    }
    ::v-deep .el-form-item {
      &.inline {
        display: inline-block;
      }
      &:first-child {
        margin: 24px 0;
      }
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      ::v-deep .el-form-item__label {
        height: 32px;
        line-height: 32px;
        padding: 0;
        margin: 0;
      }
      ::v-deep .el-form-item__content {
        height: 100%;
        padding-left: 20px;
        line-height: 32px;
      }
      ::v-deep .el-form-item__error {
        padding-left: 20px;
      }
    }
    .width635 {
      width: 635px;
    }
    .ui-box-from-practiceInfo-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      ::v-deep .el-input-group__prepend {
        background: #fff;
        border-radius: 0;
        border: none;
        border-right: 1px solid #dcdfe6;
      }
      ::v-deep .el-input-group__append {
        background: #fff;
        border-radius: 0;
        border: none;
        border-left: 1px solid #dcdfe6;
      }
      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
    }
    .ui-box-from-practiceInfo-tips {
      color: #999999;
    }
  }
}

.ui-box-item {
  display: flex;
  margin: 11px 0 10px 0;
  .itemInfo {
    flex: 1;
    display: flex;
    margin: 0 10px 0 24px;
    width: 166px;
    background-color: whitesmoke;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  .itemInfo-detail {
    display: inline-block;
    margin: 0 24px 0 0;
    width: calc(100% - 224px);
    background-color: whitesmoke;
    .ui-box-from-practiceInfo-img {
      cursor: pointer;
      line-height: 32px;
      margin-right: 8px;
    }
    .ui-box-from-practiceInfo-text {
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-right: 30px;
    }
    ::v-deep .el-form-item {
      &.inline {
        display: inline-block;
      }
      &:first-child {
        margin: 24px 0;
      }
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      .el-form-item__label {
        height: 32px;
        line-height: 32px;
        padding: 0;
        margin: 0;
      }
      .el-form-item__content {
        height: 100%;
        padding-left: 20px;
        line-height: 32px;
      }
      .el-form-item__error {
        padding-left: 20px;
      }
    }
    .width635 {
      width: 635px;
    }
    .ui-box-from-practiceInfo-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      ::v-deep .el-input-group__prepend {
        background: #fff;
        border-radius: 0;
        border: none;
        border-right: 1px solid #dcdfe6;
      }
      ::v-deep .el-input-group__append {
        background: #fff;
        border-radius: 0;
        border: none;
        border-left: 1px solid #dcdfe6;
      }
      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
    }
    .ui-box-from-practiceInfo-tips {
      color: #999999;
    }
  }
}
</style>
 