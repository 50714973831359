<template>
  <div class="ui-box-item">
    <div class="itemInfo">
      <span class="item-label">咨询信息</span>
    </div>
    <div class="itemInfo-detail">
      <el-form-item label="医生状态" prop="tbOnlineState">
        <el-switch v-model="form.tbOnlineState" :active-value="1" :inactive-value="2" :disabled="pageType==='see'"></el-switch>
      </el-form-item>
      <el-form-item label="开启咨询" prop="tbIsConsulting">
        <el-switch v-model="form.tbIsConsulting" :active-value="2" :inactive-value="1" :disabled="pageType==='see'" @change="tbIsConsultingChange"></el-switch>
      </el-form-item>
      <el-form-item label="开启图文咨询" prop="tbGraphicConsulting" class="inline">
        <el-switch v-model="form.tbGraphicConsulting" :active-value="2" :inactive-value="1" :disabled="pageType==='see'" @change="tbGraphicConsultingChange"></el-switch>
      </el-form-item>
      <el-form-item prop="tbGraphicPrice" class="inline top" label-width="0px">
        <el-input class="ui-box-from-consultingInfo-input " v-model="form.tbGraphicPrice" :disabled="pageType==='see'">
          <template slot="prepend">价格</template>
          <template slot="append">元/次</template>
        </el-input>
      </el-form-item>
      <br />
      <el-form-item label="开启电话咨询" prop="tbPhoneConsulting" class="inline">
        <el-switch v-model="form.tbPhoneConsulting" :active-value="2" :inactive-value="1" :disabled="pageType==='see'" @change="tbPhoneConsultingChange"></el-switch>
      </el-form-item>
      <el-form-item prop="tbPhonePrice" class="inline top" label-width="0px">
        <el-input class="ui-box-from-consultingInfo-input " v-model="form.tbPhonePrice" :disabled="pageType==='see'">
          <template slot="prepend">价格</template>
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="购买是否赠送积分" prop="tbIntegralConfig.tbIsOpenGive" class="relative">
        <el-radio-group v-model="tbIntegralConfig.tbIsOpenGive" :disabled="pageType==='see'">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="赠送积分" prop="tbIntegralConfig.tbGiveValue">
        <el-input class="ui-box-from-consultingInfo-input" v-model="tbIntegralConfig.tbGiveValue" :disabled="pageType==='see'">
        </el-input>
      </el-form-item>
      <!-- <el-form-item prop="tbPhoneMinutes" class="inline top" label-width="0px">
        <el-input class="ui-box-from-consultingInfo-input " v-model="form.tbPhoneMinutes" :disabled="pageType==='see'">
          <template slot="prepend">时间</template>
          <template slot="append">分钟</template>
        </el-input>
      </el-form-item> -->
      <el-form-item label="开启医疗包" prop="tbMedicalPackage">
        <el-switch v-model="form.tbMedicalPackage" :active-value="2" :inactive-value="1" :disabled="pageType==='see'"></el-switch>
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'consultingInfo',
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          'tbOnlineState': '',
          'tbIsConsulting': '',
          'tbGraphicConsulting': '',
          'tbGraphicPrice': '',
          'tbPhoneConsulting': '',
          'tbPhonePrice': '',
          'tbPhoneMinutes': '',
          'tbAuditState': '',
          'tbMedicalPackage': ''
        }
      }
    },
    tbIntegralConfig: {
      type: Object,
      default: () => { }
    },
    isSee: Boolean,
    pageType: String,
    status: Number
  },
  data () {
    return {

    }
  },
  computed: {

  },
  created () {

  },
  methods: {
    tbIsConsultingChange (val) {
      if (val === 1) {
        this.form.tbGraphicConsulting = 1
        this.form.tbPhoneConsulting = 1
        this.$emit('update:form', this.form)
      }
    },
    tbGraphicConsultingChange (val) {
      if (val === 2) {
        this.form.tbIsConsulting = 2
        this.$emit('update:form', this.form)
      }
    },
    tbPhoneConsultingChange (val) {
      if (val === 2) {
        this.form.tbIsConsulting = 2
        this.$emit('update:form', this.form)
      }
    }
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.ui-box-item {
  display: flex;
  margin: 11px 0 10px 0;
  .itemInfo {
    flex: 1;
    display: flex;
    margin: 0 10px 0 24px;
    width: 166px;
    background-color: whitesmoke;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  .itemInfo-detail {
    display: inline-block;
    margin: 0 24px 0 0;
    width: calc(100% - 224px);
    background-color: whitesmoke;
    .ui-box-from-consultingInfo-img {
      cursor: pointer;
      line-height: 32px;
      margin-right: 8px;
    }
    .ui-box-from-consultingInfo-text {
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-right: 30px;
    }
    ::v-deep .el-form-item {
      &.top {
        vertical-align: top;
      }
      &.inline {
        display: inline-block;
      }
      &:first-child {
        margin: 24px 0;
      }
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      ::v-deep .el-form-item__label {
        height: 32px;
        line-height: 32px;
        padding: 0;
        margin: 0;
      }
      ::v-deep .el-form-item__content {
        height: 100%;
        padding-left: 20px;
        line-height: 32px;
      }
      ::v-deep .el-form-item__error {
        padding-left: 20px;
      }
    }
    .ui-box-from-consultingInfo-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      ::v-deep .el-input-group__prepend {
        background: #fff;
        border-radius: 0;
        border: none;
        border-right: 1px solid #dcdfe6;
      }
      ::v-deep .el-input-group__append {
        background: #fff;
        border-radius: 0;
        border: none;
        border-left: 1px solid #dcdfe6;
      }
      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
    }
    .ui-box-from-consultingInfo-tips {
      color: #999999;
    }
  }
}

.ui-box-item {
  display: flex;
  margin: 11px 0 10px 0;
  .itemInfo {
    flex: 1;
    display: flex;
    margin: 0 10px 0 24px;
    width: 166px;
    background-color: whitesmoke;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  .itemInfo-detail {
    display: inline-block;
    margin: 0 24px 0 0;
    width: calc(100% - 224px);
    background-color: whitesmoke;
    .ui-box-from-consultingInfo-img {
      cursor: pointer;
      line-height: 32px;
      margin-right: 8px;
    }
    .ui-box-from-consultingInfo-text {
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-right: 30px;
    }
    ::v-deep .el-form-item {
      &.top {
        vertical-align: top;
      }
      &.inline {
        display: inline-block;
      }
      &:first-child {
        margin: 24px 0;
      }
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      .el-form-item__label {
        height: 32px;
        line-height: 32px;
        padding: 0;
        margin: 0;
      }
      .el-form-item__content {
        height: 100%;
        padding-left: 20px;
        line-height: 32px;
      }
      .el-form-item__error {
        padding-left: 20px;
      }
    }
    .ui-box-from-consultingInfo-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      ::v-deep .el-input-group__prepend {
        background: #fff;
        border-radius: 0;
        border: none;
        border-right: 1px solid #dcdfe6;
      }
      ::v-deep .el-input-group__append {
        background: #fff;
        border-radius: 0;
        border: none;
        border-left: 1px solid #dcdfe6;
      }
      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
    }
    .ui-box-from-consultingInfo-tips {
      color: #999999;
    }
  }
}
</style>
 