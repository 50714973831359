<template>
  <div class="ui-box-item">
    <div class="itemInfo">
      <span class="item-label">个人信息</span>
    </div>
    <div class="itemInfo-detail">
      <el-row>
        <el-form-item label="姓名" prop="tbName">
          <el-input class="ui-box-from-userInfo-input width635" v-model="form.tbName" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="tbPhone">
          <el-input class="ui-box-from-userInfo-input width635" v-model="form.tbPhone" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="所在区域" prop="tbAreaId">
          <el-cascader class="ui-box-from-userInfo-input width635" clearable ref="areaRef" popper-class="cascader-tree" v-model="area.areaData" :options="areaOptions" :props="areaProps" @change="cascaderChange" :disabled="pageType==='see'"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="detailedAddress">
          <el-input class="ui-box-from-userInfo-input width635" v-model="form.detailedAddress" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="医护类型" prop="tbDoctorType">
          <el-select class="ui-box-from-userInfo-input width635" clearable v-model="form.tbDoctorType" placeholder="请选择" :disabled="pageType==='see'">
            <el-option v-for="item in doctorTypeList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="医生助理" prop="tbDoctorStaff">
          <el-select class="ui-box-from-userInfo-input width635" filterable clearable v-model="form.tbDoctorStaff" placeholder="请选择" :disabled="pageType==='see'">
            <el-option v-for="item in staffList" :key="item.tbId" :label="item.tbName" :value="item.tbId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="孕妈数量" prop="tbUserNumber">
          <el-input class="ui-box-from-userInfo-input width635" v-model="form.tbUserNumber" disabled></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="tbIdCard">
          <el-input class="ui-box-from-userInfo-input width635" v-model="form.tbIdCard" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="注册是否加入圈子" prop="tbIsAddCircle" class="relative">
          <el-radio-group v-model="form.tbIsAddCircle" :disabled="pageType==='see'">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否为孕妇学校的医生" prop="tbIsSchool" class="relative">
          <el-radio-group v-model="form.tbIsSchool" :disabled="pageType==='see'">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="身份证正面" prop="tbCardPositive">
              <upload :uploadSize="104" :isSee="isSee" :isPic="true" :limit="1" :fileList.sync="form.tbCardPositiveTemp" validateRef="tbCardPositive" @onSuccess="uploadSuccess" :saveFileList.sync="form.tbCardPositive"></upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证反面" prop="tbCardReverse">
              <upload :uploadSize="104" :isSee="isSee" :isPic="true" :limit="1" :fileList.sync="form.tbCardReverseTemp" validateRef="tbCardReverse" @onSuccess="uploadSuccess" :saveFileList.sync="form.tbCardReverse"></upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="医生名片" prop="tbDoctorCard">
          <upload :uploadSize="104" :isSee="isSee" :isPic="true" :limit="1" :fileList.sync="form.tbDoctorCardTemp" validateRef="tbDoctorCard" @onSuccess="uploadSuccess" :saveFileList.sync="form.tbDoctorCard"></upload>
        </el-form-item>
        <el-row>
          <el-col :span="3">
            <el-form-item label="医生公众号名片" prop="tbAppletsQrcode">
              <upload :uploadSize="104" :isSee="true" :isPic="true" :limit="1" :fileList.sync="form.tbAppletsQrcodeTemp" validateRef="tbAppletsQrcode" @onSuccess="uploadSuccess" :saveFileList.sync="form.tbAppletsQrcode"></upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="">
              <el-button class="ui-box-button" type="primary" v-show="pageType!=='see'" @click="createAppletsQrcode">生成公众号名片</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="医生大头像(5:2)" prop="tbBigHead">
          <upload :uploadSize="104" :isSee="isSee" :isPic="true" :limit="1" :fileList.sync="form.tbBigHeadTemp" validateRef="tbBigHead" @onSuccess="uploadSuccess" :saveFileList.sync="form.tbBigHead"></upload>
        </el-form-item>
      </el-row>

      <canvas class="canvasClass" ref="canvas" width="630" height="504"></canvas>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import upload from '@/components/upload/upload.vue'
export default {
  name: 'userInfo',
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          'tbName': '',
          'tbPhone': '',
          'tbProvinceId': '',
          'tbProvinceName': '',
          'tbCityId': '',
          'tbCityName': '',
          'tbAreaId': '',
          'tbAreaName': '',
          'detailedAddress': '',
          'tbDoctorType': '',
          'tbDoctorStaff': '',
          'tbUserNumber': '',
          'tbInArea': '',
          'tbIdCard': '',
          'tbCardPositive': [],
          'tbCardPositiveTemp': [],
          'tbCardReverse': [],
          'tbCardReverseTemp': [],
          'tbDoctorCard': [],
          'tbDoctorCardTemp': [],
          'tbAppletsQrcode': [],
          'tbAppletsQrcodeTemp': [],
          'tbBigHead': [],
          'tbBigHeadTemp': [],
          'tbHospitalId': '',
          'tbHospitalName': '',
          'tbCertificateNumber': '',
          'tbBecomDoctorYear': '',
          'tbGoodAt': '',
          'tbIntroduce': '',
          'tbDepartmentId': '',
          'tbDepartmentName': '',
          'tbProfessionTitleId': '',
          'tbProfessionTitleName': '',
          'tbGoodAtTitle': '',
          'tbIsAddCircle': 0
        }
      }
    },
    area: {
      type: Object,
      default: () => {
        return {
          areaData: []
        }
      }
    },
    isSee: Boolean,
    pageType: String,
    status: Number
  },
  data () {
    return {
      doctorBgImg: null,
      doctorImg: null,
      file: {
        item: null,
        headers: {
          'Authorization': window.sessionStorage.getItem('token') || ''
        },
        data: {
          folder: 'nurseDetail'
        }
      },
      staffList: [],
      doctorTypeList: [
        { id: 1, name: '普通' },
        { id: 2, name: 'VIP' },
        { id: 3, name: '孕妇学校校长' }
      ],
      areaProps: {
        multiple: false,
        value: 'id',
        label: 'areaName',
        children: 'children'
      }
    }
  },
  computed: {
    areaOptions () {
      if (this.areaTree.length > 0) {
        return this.areaTree[0].children
      }
      return []
    },
    ...mapGetters([
      'areaTree'
    ])
  },
  watch: {
    'form': function () {
      this.doctorBgImg = new Image();
      this.doctorBgImg.setAttribute("crossOrigin", "anonymous");
      this.doctorBgImg.src = require(`@/assets/img/doctor/doctorBg.png`)
      this.doctorImg = new Image();
      this.doctorImg.setAttribute("crossOrigin", "anonymous");
      this.doctorImg.src = this.form.tbPic ? this.setUrlOss(this.form.tbPic) : require(`@/assets/img/doctor/doctor.png`)
    }
  },
  created () {
    if (this.areaTree.length === 0) {
      this.$store.dispatch('getAreaTree', this)
    }
    this.getStaffList()
  },
  methods: {
    base64toFile (baseUrl, filename = 'file') {
      const arr = baseUrl.split(',')
      const type = arr[0].match(/:(.*?);/)[1] // 解锁图片类型
      const bytes = atob(arr[1]) // 解码base64
      let n = bytes.length
      const bufferArray = new Uint8Array(n)
      while (n--) {
        bufferArray[n] = bytes.charCodeAt(n)
      }
      return new File([bufferArray], filename, { type })
    },
    createAppletsQrcode () {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const x = canvas.width / 2;
      ctx.beginPath();
      ctx.drawImage(this.doctorBgImg, 0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "#FFFFFF";
      ctx.textAlign = "center"
      ctx.font = 'bold 32px PingFangSC';
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFFFF";
      const r = 60
      ctx.fillText(`${this.form.tbName}医生`, x, 190)
      ctx.arc(x, 75, r, 0, 2 * Math.PI);
      ctx.clip()
      ctx.drawImage(this.doctorImg, x - 59, 16, 118, 118);
      ctx.stroke();
      // 将canvas转换为图片
      const dataURL = canvas.toDataURL('image/png');
      const fileBolb = this.base64toFile(dataURL)
      const formData = new FormData()
      formData.append('file', fileBolb)
      formData.append('doctorId', this.form.tbId)
      this.$http.upload('/doctor/getAppletsQrCode', formData)
        .then(res => {
          if (res.code === 1000) {
            this.form.tbAppletsQrcodeTemp = this.form.tbAppletsQrcode = [{ name: '', url: this.$http.appHeadPicUrl + res.data }]
          }
        })
    },
    validatePhoto (rule, value, callback) {
      if (value.length === 0) {
        callback(new Error())
      } else {
        callback()
      }
    },
    uploadSuccess (ref) {
      this.$refs['form'].validateField(ref)
    },
    setPhoto (data, filed) {
      if (data[filed]) {
        data[`${filed} Temp`] = data[filed] = data[filed].split(',').map(i => {
          return {
            name: this.setUrlOss(i),
            url: this.setUrlOss(i)
          }
        })
      } else {
        data[`${filed} Temp`] = data[filed] = []
      }
    },
    getStaffList () {
      this.$http.get('/staff/selectStaffList', {})
        .then(res => {
          if (res.code === 1000) {
            this.staffList = res.data.list
          } else {
            this.$message.error(res.message)
          }
        })
    },
    cascaderChange (val) {
      if (val.length) {
        const labels = this.$refs.areaRef.getCheckedNodes()[0].pathLabels
        this.form.tbProvinceName = labels[0]
        this.form.tbCityName = labels[1]
        this.form.tbAreaName = labels[2]
        this.form.tbProvinceId = val[0]
        this.form.tbCityId = val[1]
        this.form.tbAreaId = val[2]
      } else {
        this.form.tbProvinceName = ''
        this.form.tbCityName = ''
        this.form.tbAreaName = ''
        this.form.tbProvinceId = ''
        this.form.tbCityId = ''
        this.form.tbAreaId = ''
      }
    }
  },
  components: {
    upload
  }
}
</script>

<style lang="scss" scoped>
.canvasClass {
  position: absolute;
  left: -1000px;
  top: -1000px;
}
.ui-box-item {
  display: flex;
  margin: 11px 0 10px 0;
  .itemInfo {
    flex: 1;
    display: flex;
    margin: 0 10px 0 24px;
    width: 166px;
    background-color: whitesmoke;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  .itemInfo-detail {
    display: inline-block;
    margin: 0 24px 0 0;
    width: calc(100% - 224px);
    background-color: whitesmoke;
    .ui-box-from-userInfo-img {
      cursor: pointer;
      line-height: 32px;
      margin-right: 8px;
    }
    .ui-box-from-userInfo-text {
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-right: 30px;
    }
    ::v-deep .el-form-item {
      &.inline {
        display: inline-block;
      }
      &:first-child {
        margin: 24px 0;
      }
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      ::v-deep .el-form-item__label {
        height: 32px;
        line-height: 32px;
        padding: 0;
        margin: 0;
      }
      ::v-deep .el-form-item__content {
        height: 100%;
        padding-left: 20px;
        line-height: 32px;
      }
      ::v-deep .el-form-item__error {
        padding-left: 20px;
      }
    }
    .ui-box-from-userInfo-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
    }
    .ui-box-from-userInfo-tips {
      color: #999999;
    }
  }
}

.ui-box-item {
  display: flex;
  margin: 11px 0 10px 0;
  .itemInfo {
    flex: 1;
    display: flex;
    margin: 0 10px 0 24px;
    width: 166px;
    background-color: whitesmoke;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  .itemInfo-detail {
    display: inline-block;
    margin: 0 24px 0 0;
    width: calc(100% - 224px);
    background-color: whitesmoke;
    .ui-box-from-userInfo-img {
      cursor: pointer;
      line-height: 32px;
      margin-right: 8px;
    }
    .ui-box-from-userInfo-text {
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-right: 30px;
    }
    ::v-deep .el-form-item {
      &.inline {
        display: inline-block;
      }
      &:first-child {
        margin: 24px 0;
      }
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      .el-form-item__label {
        height: 32px;
        line-height: 32px;
        padding: 0;
        margin: 0;
      }
      .el-form-item__content {
        height: 100%;
        padding-left: 20px;
        line-height: 32px;
      }
      .el-form-item__error {
        padding-left: 20px;
      }
    }
    .ui-box-from-userInfo-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
    }
    .ui-box-from-userInfo-tips {
      color: #999999;
    }
  }

  .ui-box-button {
    padding: 10px 25px;
  }
}
</style>
 