<template>
  <div class="ui-box-item">
    <div class="itemInfo">
      <span class="item-label">执业信息</span>
    </div>
    <div class="itemInfo-detail">
      <el-row>
        <!-- <el-col :span="12"> -->
        <el-form-item label="医院" prop="tbDoctorStaff">
          <el-select class="ui-box-from-practiceInfo-input width635" filterable clearable v-model="form.tbHospitalId" placeholder="请选择" :disabled="pageType==='see'" @change="hospitalChange">
            <el-option v-for="item in hospitalList" :key="item.tbId" :label="item.tbHospitalName" :value="item.tbId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证书编号" prop="tbCertificateNumber">
          <el-input class="ui-box-from-practiceInfo-input width635" v-model="form.tbCertificateNumber" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="从医年份" prop="tbBecomDoctorYear">
          <el-input class="ui-box-from-practiceInfo-input width635" v-Int v-model="form.tbBecomDoctorYear" :disabled="pageType==='see'">
            <template slot="append">年</template>
          </el-input>
        </el-form-item>
        <el-form-item label="擅长" prop="tbGoodAt">
          <el-input class="width635" type="textarea" :rows="4" v-model="form.tbGoodAt" :maxlength="255" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="tbIntroduce">
          <el-input class="width635" type="textarea" :rows="4" v-model="form.tbIntroduce" :maxlength="255" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <!-- </el-col> -->
        <!-- <el-col :span="12"> -->
        <el-form-item label="科室" prop="tbDepartmentId">
          <el-select class="ui-box-from-practiceInfo-input width635" filterable clearable v-model="form.tbDepartmentId" placeholder="请选择" :disabled="pageType==='see'" @change="departmentChange">
            <el-option v-for="item in departmentList" :key="item.tbId" :label="item.tbDepartmentName" :value="item.tbId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职称" prop="tbProfessionTitleId">
          <el-select class="ui-box-from-practiceInfo-input width635" filterable clearable v-model="form.tbProfessionTitleId" placeholder="请选择" :disabled="pageType==='see'" @change="professionChange">
            <el-option v-for="item in professionList" :key="item.tbId" :label="item.tbProfessionName" :value="item.tbId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="擅长标签" prop="tbGoodAtTitle">
          <el-input class="width635" type="textarea" :rows="4" v-model="form.tbGoodAtTitle" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <!-- </el-col> -->
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'practiceInfo',
  props: {
    hospitalList: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => {
        return {
          'tbName': '',
          'tbPhone': '',
          'tbProvinceId': '',
          'tbProvinceName': '',
          'tbCityId': '',
          'tbCityName': '',
          'tbAreaId': '',
          'tbAreaName': '',
          'detailedAddress': '',
          'tbDoctorType': '',
          'tbDoctorStaff': '',
          'tbUserNumber': '',
          'tbIdCard': '',
          'tbInArea': '',
          'tbCardPositive': [],
          'tbCardPositiveTemp': [],
          'tbCardReverse': [],
          'tbCardReverseTemp': [],
          'tbDoctorCard': [],
          'tbDoctorCardTemp': [],
          'tbBigHead': [],
          'tbBigHeadTemp': [],
          'tbHospitalId': '',
          'tbHospitalName': '',
          'tbCertificateNumber': '',
          'tbBecomDoctorYear': '',
          'tbGoodAt': '',
          'tbIntroduce': '',
          'tbDepartmentId': '',
          'tbDepartmentName': '',
          'tbProfessionTitleId': '',
          'tbProfessionTitleName': '',
          'tbGoodAtTitle': ''
        }
      }
    },
    isSee: Boolean,
    pageType: String,
    status: Number
  },
  data () {
    return {
      professionList: [],
      departmentList: [],
      doctorTypeList: [
        { id: 1, name: '普通' },
        { id: 2, name: 'VIP' },
        { id: 3, name: '孕妇学校校长' }
      ]
    }
  },
  computed: {
  },
  created () {
    this.getProfessionList()
    this.getDepartmentList()
  },
  methods: {
    professionChange () {
      this.form.tbProfessionTitleName = this.professionList.find(({ tbId }) => this.form.tbProfessionTitleId === tbId)?.tbProfessionName || ''
    },
    departmentChange () {
      this.form.tbDepartmentName = this.departmentList.find(({ tbId }) => this.form.tbDepartmentId === tbId)?.tbDepartmentName || ''
    },
    hospitalChange () {
      this.form.tbHospitalName = this.hospitalList.find(({ tbId }) => this.form.tbHospitalId === tbId)?.tbHospitalName || ''
    },
    getProfessionList () {
      this.$http.get(`/profession/findList`, {})
        .then(res => {
          if (res.code === 1000) {
            this.professionList = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    getDepartmentList () {
      this.$http.get(`/department/findList`, {})
        .then(res => {
          if (res.code === 1000) {
            this.departmentList = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    }
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.ui-box-item {
  display: flex;
  margin: 11px 0 10px 0;
  .itemInfo {
    flex: 1;
    display: flex;
    margin: 0 10px 0 24px;
    width: 166px;
    background-color: whitesmoke;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  .itemInfo-detail {
    display: inline-block;
    margin: 0 24px 0 0;
    width: calc(100% - 224px);
    background-color: whitesmoke;
    .ui-box-from-practiceInfo-img {
      cursor: pointer;
      line-height: 32px;
      margin-right: 8px;
    }
    .ui-box-from-practiceInfo-text {
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-right: 30px;
    }
    ::v-deep .el-form-item {
      &.inline {
        display: inline-block;
      }
      &:first-child {
        margin: 24px 0;
      }
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      ::v-deep .el-form-item__label {
        height: 32px;
        line-height: 32px;
        padding: 0;
        margin: 0;
      }
      ::v-deep .el-form-item__content {
        height: 100%;
        padding-left: 20px;
        line-height: 32px;
      }
      ::v-deep .el-form-item__error {
        padding-left: 20px;
      }
    }
    .width635 {
      width: 635px;
    }
    .ui-box-from-practiceInfo-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      ::v-deep .el-input-group__prepend {
        background: #fff;
        border-radius: 0;
        border: none;
        border-right: 1px solid #dcdfe6;
      }
      ::v-deep .el-input-group__append {
        background: #fff;
        border-radius: 0;
        border: none;
        border-left: 1px solid #dcdfe6;
      }
      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
    }
    .ui-box-from-practiceInfo-tips {
      color: #999999;
    }
  }
}

.ui-box-item {
  display: flex;
  margin: 11px 0 10px 0;
  .itemInfo {
    flex: 1;
    display: flex;
    margin: 0 10px 0 24px;
    width: 166px;
    background-color: whitesmoke;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  .itemInfo-detail {
    display: inline-block;
    margin: 0 24px 0 0;
    width: calc(100% - 224px);
    background-color: whitesmoke;
    .ui-box-from-practiceInfo-img {
      cursor: pointer;
      line-height: 32px;
      margin-right: 8px;
    }
    .ui-box-from-practiceInfo-text {
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-right: 30px;
    }
    ::v-deep .el-form-item {
      &.inline {
        display: inline-block;
      }
      &:first-child {
        margin: 24px 0;
      }
      &:not(:first-child) {
        margin-bottom: 24px;
      }
      .el-form-item__label {
        height: 32px;
        line-height: 32px;
        padding: 0;
        margin: 0;
      }
      .el-form-item__content {
        height: 100%;
        padding-left: 20px;
        line-height: 32px;
      }
      .el-form-item__error {
        padding-left: 20px;
      }
    }
    .width635 {
      width: 635px;
    }
    .ui-box-from-practiceInfo-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      ::v-deep .el-input-group__prepend {
        background: #fff;
        border-radius: 0;
        border: none;
        border-right: 1px solid #dcdfe6;
      }
      ::v-deep .el-input-group__append {
        background: #fff;
        border-radius: 0;
        border: none;
        border-left: 1px solid #dcdfe6;
      }
      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
    }
    .ui-box-from-practiceInfo-tips {
      color: #999999;
    }
  }
}
</style>
 