<template>
  <div class="ui-content">
    <div class="ui-seachBox height104 ">
      <el-row class="row-bg">
        <el-col :span="10">
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-type">任务收益</div>
          </div>
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-value">{{accountDetail.tbBalance||0}}</div>
            <div class="ui-seachBox-money-label">当前余额(元)</div>
          </div>
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-value">{{accountDetail.totalIncome||0}}</div>
            <div class="ui-seachBox-money-label">累计收入(元)</div>
          </div>
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-value">{{accountDetail.tbLockBalance||0}}</div>
            <div class="ui-seachBox-money-label">冻结金额(元)</div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-type">内容收益</div>
          </div>
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-value">{{accountDetail.tbSimpBalance||0}}</div>
            <div class="ui-seachBox-money-label">当前余额(元)</div>
          </div>
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-value">{{accountDetail.simpTotalIncome||0}}</div>
            <div class="ui-seachBox-money-label">累计收入(元)</div>
          </div>
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-value">{{accountDetail.tbLockSimpBalance||0}}</div>
            <div class="ui-seachBox-money-label">冻结金额(元)</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-type">提现总数</div>
          </div>
          <div class="ui-seachBox-money">
            <div class="ui-seachBox-money-value">{{accountDetail.totalWithdrawal||0}}</div>
            <div class="ui-seachBox-money-label">提现总数(元)</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="ui-seachBox">
      <el-row class="row-bg">
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>变动原因</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select class="ui-seachBox-input" clearable v-model="seach.tbType" placeholder="请选择" @change="tbTypeChange">
            <el-option v-for="item in tbTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>收益来源</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select class="ui-seachBox-input" clearable v-model="seach.consumeType" placeholder="请选择">
            <el-option v-for="item in computeConsumeType2List" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="10">
          <p class="ui-seachBox-label">
            <span>申请时间</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-date-picker class="ui-seachBox-input" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="time" type="datetimerange" range-separator="至" start-placeholder="" end-placeholder="">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row class="row-bg">
        <el-col :span="7">
          <p class="ui-seachBox-label">
            <span>收益类型</span>
            <span class="ui-seachBox-Symbol">:</span>
          </p>
          <el-select class="ui-seachBox-input" clearable v-model="seach.incomeType" placeholder="请选择" @change="incomeTypeChange">
            <el-option label="任务收益" :value="1"></el-option>
            <el-option label="内容收益" :value="2"></el-option>
          </el-select>
        </el-col>
        <el-button class="ui-seachBox-submit" type="primary" @click="getList">查 询</el-button>
        <el-button class="ui-seachBox-reset" @click="reset">重 置</el-button>
      </el-row>
    </div>
    <div class="ui-tableBox">
      <div class="ui-tableBox-bar">
        <p class="ui-tableBox-title">数据列表</p>
      </div>
      <div class="ui-tableList">
        <el-table v-loading="loading" :data="tableList" :max-height="tableHeight" :default-sort="{prop: 'tbSort', order: 'ascending'}" border style="width: 100%" :header-cell-style="{background: '#FAFAFA'}">
          <el-table-column align="center" type="index" width="68px" label="序号">
          </el-table-column>
          <el-table-column align="center" label="姓名">
            <template>{{$route.query.tbName}}</template>
          </el-table-column>
          <el-table-column align="center" label="变动原因">
            <template slot-scope="{row}">
              {{tbTypeMap[row.tbType]}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="收益类型">
            <template slot-scope="{row}">
              {{row.tbType!==3?'无':row.consumeType=='9'?'任务收益':'内容收益'}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="收益来源">
            <template slot-scope="{row}">
              {{row.tbType!==3?'无':row.consumeType=='9'?changeRemarksTypeMap[row.changeRemarks]:consumeTypeMap[row.consumeType]}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="变动时间">
          </el-table-column>
          <el-table-column align="center" prop="changeCurrency" label="变动的余额(元)">
            <template slot-scope="{row}">
              {{getChange(row)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="changeFrontCurrency" label="变动前的余额(元)">
            <template slot-scope="{row}">
              {{(row.tbType===3 && row.consumeType == null) || row.consumeType == 9?row.changeFrontCurrency:row.simpChangeFrontCurrency}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="changeBackCurrency" label="变动后的余额(元)">
            <template slot-scope="{row}">
              {{(row.tbType===3 && row.consumeType == null) || row.consumeType == 9?row.changeBackCurrency:row.simpChangeBackCurrency}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination class="ui-papagination" @size-change="page.pagingSizeChange" @current-change="page.pagingCurrentChange" :total="page.total" :pageSizes="page.pageSizes" :pageSize="page.pageSize" :currentPage="page.currentPage"></pagination>
    </div>
  </div>
</template>
<script>


const files = require.context('/', false, /\.vue$/)
const suffixList = files.id.split(' ')[0].split('/').filter((a, b) => b > 2)
const fileName = 'nurseAccount'
const controller = 'doctor'
import { debounceUtils } from '@/utils/common.js'
import { mapGetters } from 'vuex'
export default {
  name: `${suffixList.join('')}${fileName}`,
  data () {
    const _this = this
    return {
      time: [],
      accountDetail: {},
      tbTypeList: [
        { id: 0, name: '申请提现' },
        // { id: 1, name: '消费' },
        // { id: 2, name: '消费返还' },
        { id: 3, name: '新增收益' },
        { id: 4, name: '提现失败返还' }
      ],
      consumeTypeList: [
        // { id: 1, name: '商品订单' },
        // { id: 2, name: '音乐订单' },
        { id: 3, name: '学堂订单' },
        // { id: 4, name: '孕动订单' },
        // { id: 5, name: '医疗包' },
        { id: 6, name: '图文咨询' },
        { id: 7, name: '电话咨询' },
        { id: 8, name: '热门问题' },
        { id: 9, name: '后台任务分配' },
        { id: 10, name: '圈子订单' }
      ],
      consumeType2List: [
        // { id: 1, name: '商品订单' },
        // { id: 2, name: '音乐订单' },
        { id: 3, name: '学堂订单' },
        // { id: 4, name: '孕动订单' },
        // { id: 5, name: '医疗包' },
        { id: 6, name: '图文咨询' },
        { id: 7, name: '电话咨询' },
        { id: 8, name: '热门问题' },
        { id: 10, name: '圈子订单' },
        { id: 11, name: '签到' },
        { id: 12, name: '发文章视频' },
        { id: 13, name: '阅读观看' },
        { id: 14, name: '答题' },
        { id: 15, name: '完成指定任务' }
      ],
      changeRemarksTypeList: [
        { id: 1, name: '签到' },
        { id: 2, name: '发文章视频' },
        { id: 3, name: '阅读观看' },
        { id: 4, name: '答题' },
        { id: 5, name: '完成指定任务' }
      ],
      filePath: ['', ...suffixList, fileName].join('/'),
      fileName: fileName,
      detailName: `${fileName}Detail`,
      loading: true,
      tableHeight: null,
      page: {
        currentPage: 1, //当前页
        pageSize: 10, //每页记录数
        total: 10,
        pageSizes: [10, 20, 50, 100],
        pagingSizeChange (value) {
          _this.page.currentPage = 1
          _this.page.pageSize = value
          _this.getList()
        },
        pagingCurrentChange (value) {
          _this.page.currentPage = value
          _this.getList()
        }
      },
      tableList: [],
      seach: {
        tbType: '',
        consumeType: '',
        incomeType: ''
      }
    }
  },
  computed: {
    computeConsumeType2List () {
      if (!this.seach.incomeType) {
        return this.consumeType2List
      } else if (this.seach.incomeType === 1) {
        return this.consumeType2List.filter(i => [11, 12, 13, 14, 15].includes(i.id))
      } else {
        return this.consumeType2List.filter(i => ![11, 12, 13, 14, 15].includes(i.id))
      }
    },
    changeRemarksTypeMap () {
      return this.changeRemarksTypeList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    consumeTypeMap () {
      return this.consumeTypeList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    tbTypeMap () {
      return this.tbTypeList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    ...mapGetters([
      'lastBreadCrumb',
      'refreshPage'
    ])
  },
  watch: {
    $route (to, from) {
      if (to.path === this.filePath && this.refreshPage) {
        this.getList()
        this.findAccountDetail()
        this.$store.dispatch('setRefreshPage', false)
      }
    }
  },
  methods: {
    getChange (row) {
      if (row.tbType === 0 || row.tbType === 4) {
        if (row.consumeType == 9) {
          if (Number(row.changeCurrency) === 0) {
            return Number(row.changeCurrency)
          } else {
            return Number(row.changeFrontCurrency) >= Number(row.changeBackCurrency) ? `-${row.changeCurrency}` : `+${row.changeCurrency}`
          }
        } else {
          if (Number(row.simpChangeCurrency) === 0) {
            return Number(row.simpChangeCurrency)
          } else {
            return Number(row.simpChangeFrontCurrency) >= Number(row.simpChangeBackCurrency) ? `-${row.simpChangeCurrency}` : `+${row.simpChangeCurrency}`
          }
        }
      } else if (row.tbType === 3) {
        if (row.consumeType == null || row.consumeType == 9) {
          if (Number(row.changeCurrency) === 0) {
            return Number(row.changeCurrency)
          } else {
            return Number(row.changeFrontCurrency) >= Number(row.changeBackCurrency) ? `-${row.changeCurrency}` : `+${row.changeCurrency}`
          }
        } else {
          if (Number(row.simpChangeCurrency) === 0) {
            return Number(row.simpChangeCurrency)
          } else {
            return Number(row.simpChangeFrontCurrency) >= Number(row.simpChangeBackCurrency) ? `-${row.simpChangeCurrency}` : `+${row.simpChangeCurrency}`
          }
        }
      }
    },
    tbTypeChange (val) {
      if ([0, 4].includes(val)) {
        this.seach.consumeType = ''
        this.seach.incomeType = ''
      }
    },
    incomeTypeChange (val) {
      if (val) {
        this.seach.tbType = 3
        if (val === 1 && ![11, 12, 13, 14, 15].includes(this.seach.consumeType)) {
          this.seach.consumeType = null
        }
        if (val === 2 && [11, 12, 13, 14, 15].includes(this.seach.consumeType)) {
          this.seach.consumeType = null
        }
      }
    },
    reset () {
      this.seach = {
        tbDoctorId: this.$route.query.tbId,
        tbType: '',
        consumeType: '',
        incomeType: ''
      }
      this.time = []
    },
    findAccountDetail () {
      this.$http.get(`/${controller}/findAccountDetail`, {
        tbDoctorId: this.$route.query.tbId
      })
        .then(res => {
          this.loading = false
          if (res.code === 1000) {
            this.accountDetail = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    getList: debounceUtils(function () {
      this.loading = true
      this.seach.page = this.page.currentPage
      this.seach.size = this.page.pageSize
      this.seach.tbDoctorId = this.$route.query.tbId
      if (this.time.length === 2) {
        this.seach.startTime = this.time[0]
        this.seach.endTime = this.time[1]
      } else {
        delete this.seach.startTime
        delete this.seach.endTime
      }
      const seach = JSON.parse(JSON.stringify(this.seach))
      if (seach.consumeType > 10) {
        seach.changeRemarks = seach.consumeType - 10
        seach.consumeType = 9
      }
      if (this.seach.incomeType === 1) {
        seach.consumeType = 9
      }
      this.$http.get(`/${controller}/findAccountList`, seach)
        .then(res => {
          this.loading = false
          if (res.code === 1000) {
            this.tableList = res.data.list
            this.page.total = res.data.total
          } else {
            this.$message.error(res.message)
          }
        })
    }, 500)
  },
  destroyed () {
    this.destroyResizeListen(620)
  },
  created () {
    this.addResizeListen(620)
  },
  mounted () {
    this.getList()
    this.findAccountDetail()
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-seachBox {
    margin: 0 24px 24px;
    width: calc(100% - 48px);
    height: 136px;
    background: #ffffff;
    border-radius: 2px;
    &.height104 {
      height: 104px;
    }
    &.marginbottom0 {
      margin-bottom: 0;
    }
    .row-bg {
      padding: 24px 24px 0;
      .ui-seachBox-money {
        display: inline-block;
        margin-left: 2%;
        margin-right: 8%;
        vertical-align: top;
        .ui-seachBox-money-type {
          height: 64px;
          line-height: 64px;
          width: 110px;
          text-align: right;
        }
        .ui-seachBox-money-value {
          width: 75px;
          height: 42px;
          font-size: 30px;
          font-weight: 500;
          color: #333333;
          line-height: 42px;
        }
        .ui-seachBox-money-label {
          width: 91px;
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
      .ui-seachBox-label {
        display: inline-block;
        text-align: right;
        width: 100px;
        height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        vertical-align: top;
      }
      .ui-seachBox-label2 {
        display: inline-block;
        text-align: right;
        height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        vertical-align: top;
        margin-right: 10px;
      }
      .ui-seachBox-box-switch {
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .ui-seachBox-Symbol {
        margin-left: 3px;
        margin-right: 5px;
      }
      .ui-seachBox-input {
        display: inline-block;
        width: calc(100% - 100px);
        height: 32px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
        ::v-deep .el-icon-circle-close,
        ::v-deep .el-icon-arrow-up,
        ::v-deep .el-icon-date,
        ::v-deep .el-range__icon,
        ::v-deep .el-range-separator,
        ::v-deep .el-range-input,
        ::v-deep .el-range__close-icon,
        ::v-deep .el-input__inner {
          vertical-align: top;
          height: 30px;
          line-height: 30px;
          border: none;
        }
      }
      .ui-seachBox-box-label {
        display: inline-block;
        width: calc(100% - 100px);
        height: 32px;
        line-height: 32px;
      }
    }
    .ui-seachBox-reset {
      margin: 0 0 0 8px;
      float: right;
      border-radius: 2px;
      padding: 9px 15px;
      &:focus {
        outline: none;
      }
    }
    .ui-seachBox-submit {
      padding: 9px 15px;
      margin: 0 24px 0 8px;
      float: right;
      border-radius: 2px;
      &:focus {
        outline: none;
      }
    }
  }
  .ui-tableBox {
    margin: 0 24px;
    width: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px;
    height: calc(100% - 312px);
    position: relative;
    .ui-tableBox-bar {
      position: relative;
      margin: 0 24px;
      height: 64px;
      .ui-tableBox-title {
        height: 24px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
        display: inline-block;
        margin-top: 20px;
      }
      .ui-tableBox-button {
        width: 108px;
        position: relative;
        float: right;
        top: 16px;
        margin-left: 24px;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        &.export {
          padding: 8px 15px;
          width: auto;
        }
        ::v-deep .el-icon-arrow-up,
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
          border: none;
          &::-webkit-input-placeholder {
            color: #606266;
          }
          &::-moz-placeholder {
            color: #606266;
          }
          &::-ms-input-placeholder {
            color: #606266;
          }
        }
        &:focus {
          outline: none;
        }
      }
    }
    .ui-tableList {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      margin: 8px 24px 0 24px;
      .ui-box-tableList-img {
        width: 80px;
        height: 80px;
        background: #d8d8d8;
        display: inline-block;
        vertical-align: top;
      }
      .ui-box-tableList-label {
        margin: 0 5px 10px;
      }
      .ui-box-tableList-editImg {
        width: 43px;
        cursor: pointer;
        height: 43px;
      }
      .ui-box-tableList-tempSaveImg {
        width: 41px;
        height: 41px;
        position: absolute;
        left: -2px;
        top: -3px;
      }
      ::v-deep .el-table__body-wrapper {
        height: auto !important;
      }
      .ui-tableList-button {
        padding: 9px 17px;
        outline: none;
      }
      .ui-tableList-link.is-underline:hover:after {
        border: none;
      }
    }
  }
}
</style>