<template>
  <div class="ui-content">
    <div class="ui-box">

      <el-form ref="form" class="ui-box-from-pregnantDetail" label-position="right" :rules="rules" :model="form" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="昵称">
              <el-input class="ui-box-from-pregnantDetail-input width645" v-model="form.nickname" :disabled="pageType==='see'"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input class="ui-box-from-pregnantDetail-input width645" v-model="form.username" :disabled="pageType==='see'"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-select class="ui-box-from-pregnantDetail-input width645" clearable v-model="form.sex" :disabled="pageType==='see'">
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input class="ui-box-from-pregnantDetail-input width645" v-model="form.phone" :disabled="pageType==='see'"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-input class="ui-box-from-pregnantDetail-input width645" v-model="form.birthday" :disabled="pageType==='see'"></el-input>
            </el-form-item>
            <el-form-item label="预产期">
              <el-input class="width645" v-model="form.prepareProduction" :disabled="pageType==='see'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="头像">
              <upload :uploadSize="104" :isSee="isSee" :isPic="true" :limit="1" :fileList.sync="form.headPortraitTemp" :saveFileList.sync="form.headPortrait"></upload>
            </el-form-item>
            <el-form-item label="阶段">
              <el-select class="ui-box-from-pregnantDetail-input width645" clearable v-model="form.currentGeneration" :disabled="pageType==='see'">
                <el-option label="已怀孕" :value="1"></el-option>
                <el-option label="已出生" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="二维码">
              <upload :uploadSize="104" :isSee="isSee" :isPic="true" :limit="1" :fileList.sync="form.qrcodeTemp" :saveFileList.sync="form.qrcode"></upload>
            </el-form-item>
            <el-form-item label="收货地址">
              <el-input class="width645" v-model="form.detailedAddress" :disabled="pageType==='see'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="专属医生列表">
          <el-table :data="doctorList" border style="margin-top:24px;max-width: 1200px;" max-height="400" :header-cell-style="{background: '#FFFFFF'}">
            <el-table-column align="center" prop="tbName" label="姓名">
            </el-table-column>
            <el-table-column align="center" prop="tbPhone" label="联系方式">
            </el-table-column>
            <el-table-column align="center" prop="tbInArea" label="所在位置">
            </el-table-column>
            <el-table-column align="center" prop="tbHospitalName" label="医院">
            </el-table-column>
            <el-table-column align="center" prop="tbDepartmentName" label="科室">
            </el-table-column>
            <el-table-column align="center" prop="tbProfessionTitleName" label="职称">
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="咨询列表">
          <el-table :data="consultList" border style="margin-top:24px;max-width: 1200px;" max-height="400" :header-cell-style="{background: '#FFFFFF'}">
            <el-table-column align="center" prop="tbConsultingType" label="类型">
              <template slot-scope="{row}">
                {{consultingTypeMap[row.tbConsultingType]}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="tbPrice" label="金额(元)">
            </el-table-column>
            <el-table-column align="center" prop="tbName" label="所属医生">
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div class="ui-box-pregnantDetail-button">
        <el-button class="return" @click="returnList">返回列表</el-button>
        <el-button type="primary" class="submit" v-show="pageType!=='see'" @click="save">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
const files = require.context('/', false, /\.vue$/)
const suffixList = files.id.split(' ')[0].split('/').filter((a, b) => b > 2)
const fileName = 'pregnantDetail'
const controller = 'user'
const parentName = '孕妈/宝妈'
import { mapGetters } from 'vuex'
import upload from '@/components/upload/upload.vue'
export default {
  name: `${suffixList.join('')}${fileName}`,
  data () {
    return {
      loading: false,
      consultList: [],
      consultingTypeMap: {
        1: '医疗包',
        2: '图文咨询',
        3: '电话咨询',
        4: '热门问题',
        5: '极速咨询'
      },
      doctorList: [],
      filePath: ['', ...suffixList, fileName].join('/'),
      fileName: fileName,
      rules: {
        tbAnswer: [
          { required: true, message: '请输入解答', trigger: 'blur' }
        ],
        tbCommonProblem: [
          { required: true, message: '请输入孕妈/宝妈', trigger: 'blur' }
        ],
        tbDepartmentId: [
          { required: true, message: '请选择科室', trigger: ['blur', 'change'] }
        ]
      },
      departmentList: [],
      form: {
        qrcode: [],
        qrcodeTemp: [],
        headPortrait: [],
        headPortraitTemp: []
      }
    }
  },
  computed: {
    isSee () {
      if (Object.keys(this.lastBreadCrumb.length > 0)) {
        if (['查看孕妈/宝妈'].includes(this.lastBreadCrumb[this.fileName])) {
          return true
        }
      }
      return false
    },
    pageType () {
      if (Object.keys(this.lastBreadCrumb.length > 0)) {
        if (['新增孕妈/宝妈'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'add'
        } else if (['修改孕妈/宝妈'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'edit'
        } else if (['查看孕妈/宝妈'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'see'
        }
      }
      return 'add'
    },
    ...mapGetters([
      'lastBreadCrumb',
      'refreshPage'
    ])
  },
  watch: {
    $route (to, from) {
      if (to.path === this.filePath && this.refreshPage) {
        this.init()
        this.$store.dispatch('setRefreshPage', false)
      }
    }
  },
  methods: {
    getConsultList () {
      this.$http.get(`/consultingOrder/findUserList`, { userId: this.$route.query.tbId })
        .then(res => {
          if (res.code === 1000) {
            this.consultList = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    getDoctorList () {
      this.$http.get(`/user/getUserExclusiveDoctorList`, { userId: this.$route.query.tbId })
        .then(res => {
          if (res.code === 1000) {
            this.doctorList = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    clear () {
      this.form = {
        headPortrait: [],
        headPortraitTemp: []
      }
    },
    setPhoto (data, filed) {
      if (data[filed]) {
        data[`${filed}Temp`] = data[filed] = data[filed].split(',').map(i => {
          return {
            name: '',
            url: this.setUrlOss(i)
          }
        })
      } else {
        data[`${filed}Temp`] = data[filed] = []
      }
    },
    init () {
      this.getConsultList()
      this.getDoctorList()
      if (this.pageType !== 'add') {
        this.getById(this.$route.query.tbId)
      } else {
        this.clear()
      }
    },
    initForm (form) {
      this.setPhoto(form, 'qrcode')
      this.setPhoto(form, 'headPortrait')
      form.prepareProduction = new Date(form.prepareProduction).format('yyyy-MM-dd')
      form.birthday = form.birthday ? new Date(form.birthday).format('yyyy-MM-dd') : ''
      return form
    },
    getById (id) {
      this.$http.get(`${controller}/get`, { 'tbId': id })
        .then(res => {
          if (res.code === 1000) {
            this.form = this.initForm(res.data)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    returnList () {
      this.closeTab(this.filePath, parentName, this.fileName, ['', ...suffixList].join('/'), true)
    },
    beforeSave (form) {
      return form
    },
    save () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form))
          form = this.beforeSave(form)
          this.$http.post(`${controller}/save`, form)
            .then(res => {
              if (res.code === 1000) {
                this.returnList()
              } else {
                this.$message.error(res.message)
              }
            })
        } else {
          return false
        }
      })
    }
  },
  created () {
    this.init()
  },
  mounted () {
  },
  destroyed () {
  },
  components: {
    upload
  }
}
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    overflow-x: auto;
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-pregnantDetail {
      padding-top: 29px;
      .addButton {
        cursor: pointer;
        margin-top: 5px;
        margin-bottom: 24px;
        margin-left: 170px;
        width: 645px;
        height: 32px;
        border-radius: 2px;
        border: 1px dashed rgba(0, 0, 0, 0.25);
        font-size: 14px;
        background-color: rgba(245, 245, 245, 1);
        color: #999999;
        display: block;
        &:focus {
          outline: none;
        }
        .margin11 {
          margin-right: 11px;
        }
      }
      ::v-deep .el-form-item {
        margin-bottom: 24px;
        .ui-box-tableList-img {
          width: 80px;
          height: 80px;
          background: #d8d8d8;
          display: inline-block;
          vertical-align: top;
        }
        &.inline {
          display: inline-block;
          vertical-align: top;
        }
        &.height-auto {
          ::v-deep .el-form-item__content {
            height: auto;
          }
        }

        ::v-deep .el-form-item__label {
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
        }
        ::v-deep .el-form-item__error {
          padding-left: 20px;
        }
        ::v-deep .el-form-item__content {
          height: 100%;
          padding-left: 20px;
          line-height: 32px;
        }
        .ui-box-from-pregnantDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 48px;
        }
        .width645 {
          width: 645px;
        }
        .margin40 {
          margin-right: 40px;
        }
        .width761 {
          width: 761px;
        }

        .ui-box-from-pregnantDetail-input {
          width: 275px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &.width645 {
            width: 645px;
          }
          ::v-deep .el-icon-time,
          ::v-deep .el-icon-arrow-down,
          ::v-deep .el-icon-arrow-up,
          ::v-deep .el-icon-date,
          ::v-deep .el-icon-date,
          ::v-deep .el-range__icon,
          ::v-deep .el-range-separator,
          ::v-deep .el-range-input,
          ::v-deep .el-range__close-icon,
          ::v-deep .el-icon-circle-close,
          ::v-deep .el-icon-arrow-up,
          ::v-deep .el-icon-date,
          ::v-deep .el-input__inner {
            vertical-align: top;
            height: 30px;
            line-height: 30px;
            border: none;
          }
          ::v-deep .el-input__inner::-webkit-outer-spin-button,
          // eslint-disable-next-line no-irregular-whitespace
          ::v-deep .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          ::v-deep .el-input__inner[type="number"] {
            -moz-appearance: textfield;
          }
          ::v-deep .el-input-group__append {
            height: 30px;
            border: none;
            &::-moz-placeholder,
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
            }
          }
          ::v-deep .el-input-group__append {
            padding: 0 15px;
          }
        }
        .ui-box-from-pregnantDetail-button {
          margin-left: 20px;
          vertical-align: top;
          padding: 8px 32px;
          border-radius: 3px;
          &:focus {
            outline: none;
          }
        }
        .ui-box-from-pregnantDetail-img {
          cursor: pointer;
          line-height: 32px;
          margin-right: 8px;
        }
        .ui-box-from-pregnantDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: middle;
          height: 100%;
          margin-right: 30px;
        }
      }
    }
    .ui-box-pregnantDetail-button {
      margin-left: 217px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      .return {
        padding: 6px 25px;
        margin-right: 40px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
      .submit {
        margin-left: 0;
        padding: 6px 25px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-pregnantDetail {
      padding-top: 29px;
      ::v-deep .el-form-item {
        margin-bottom: 24px;
        .el-form-item__label {
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
        }
        .el-form-item__content {
          height: 100%;
          padding-left: 20px;
          line-height: 32px;
        }
        .ui-box-from-pregnantDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 48px;
        }
        .width645 {
          width: 645px;
        }
        .margin40 {
          margin-right: 40px;
        }
        .el-form-item__error {
          padding-left: 20px;
        }
        .ui-box-from-pregnantDetail-input {
          width: 275px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &.width645 {
            width: 645px;
          }
          .el-icon-time,
          .el-icon-arrow-down,
          .el-icon-arrow-up,
          .el-icon-date,
          .el-icon-date,
          .el-range__icon,
          .el-range-separator,
          .el-range-input,
          .el-icon-circle-close,
          .el-icon-arrow-up,
          .el-icon-date,
          .el-input__inner {
            vertical-align: top;
            height: 30px;
            line-height: 30px;
            border: none;
          }
          .el-input__inner::-webkit-outer-spin-button,
          // eslint-disable-next-line no-irregular-whitespace
          .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          .el-input__inner[type="number"] {
            -moz-appearance: textfield;
          }
          .el-input-group__append {
            height: 30px;
            border: none;
            &::-moz-placeholder,
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
            }
          }
          .el-input-group__append {
            padding: 0 15px;
          }
        }
        .ui-box-from-pregnantDetail-button {
          margin-left: 20px;
          vertical-align: top;
          padding: 8px 32px;
          border-radius: 3px;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .ui-box-pregnantDetail-button {
      margin-left: 217px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      .return {
        padding: 6px 25px;
        margin-right: 40px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
      .submit {
        margin-left: 0;
        padding: 6px 25px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>