import axios from 'axios'
import { Message } from 'element-ui'
import qs from 'qs'
import routers from '../router'
const appHeadPicUrl = process.env.VUE_APP_HeadPicUrl
axios.defaults.baseURL = process.env.VUE_APP_URL
// axios.defaults.baseURL = 'http://www.hylyljk.com/ymm-admin/'
// axios.defaults.baseURL = 'http://192.168.100.74:8201/ymm-admin/'
// axios.defaults.baseURL = 'http://192.168.100.68:8201/ymm-admin/'

axios.defaults.withCredentials = false

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.resolve(error.response)
)

function checkStatus (response) {
  if (response && (response.status === 200 || response.status === 304)) {
    if (response.data.code === 2001) {
      response.data.message = '权限不足，拒绝访问'
      routers.push('/login')
    }
    return response.data
  } else {
    const err = {
      message: '',
      code: (response && response.status) || 1001,
      status: (response && response.status) || 1001
    }
    switch (err.status) {
      case 400:
        err.message = '请求参数错误'
        break

      case 401:
        err.message = '未授权，请登录'
        break

      case 403:
        err.message = '拒绝访问，请登录'
        break

      case 404:
        err.message = `请求地址出错:`
        break

      case 408:
        err.message = '请求超时'
        break

      case 500:
        err.message = '服务器内部错误'
        break

      case 501:
        err.message = '服务未实现'
        break

      case 502:
        err.message = '网关错误'
        break

      case 503:
        err.message = '服务不可用'
        break

      case 504:
        err.message = '网关超时'
        break
      case 505:
        err.message = 'HTTP版本不受支持'
        break
      default:
        err.message = '网络错误'
        break
    }
    // 异常状态下，把错误信息返回去
    err.message = `${(response && response.config.url) || '' + err.message}`
    return err
  }
}

function checkCode (res) {
  // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
  if (!navigator.onLine) {
    Message({
      message: '网络异常，请检查您的网络',
      type: 'error',
      duration: 3 * 1000
    })
  }
  if (!res.code) {
    Message({
      message: res.message + ' ' + res.status,
      type: 'error',
      duration: 3 * 1000
    })
  }
  return res
}
function params (data) {
  for (const i in data) {
    if (data[i] === '' || data[i] === null) {
      delete data[i]
    }
  }
  return data
}
export default {
  appHeadPicUrl,
  getDrugList (data, timeout = 2000) {
    return axios({
      method: 'get',
      url: 'https://onlineprescription-api.yaojixin.com/drugInfo/getDrugInfoPageList',
      timeout: Number(timeout) ? timeout : '50000',
      params: params(data),
      withCredentials: false
    })
      .then((response) => {
        return checkStatus(response)
      })
      .then((res) => {
        return checkCode(res)
      })
  },
  post (url, data, type = 'json', timeout = 200000) {
    return axios({
      method: 'post',
      url,
      data: type === 'json' ? params(data) : qs.stringify(data),
      timeout: timeout,
      withCredentials: false,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': type === 'json' ? 'application/json' : 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': window.sessionStorage.getItem('token') || ''
      }
    })
      .then((response) => {
        return checkStatus(response)
      })
      .then((res) => {
        return checkCode(res)
      })
  },
  get (url, data, time) {
    return axios({
      method: 'get',
      url,
      params: params(data),
      timeout: Number(time) ? time : '50000',
      withCredentials: false,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': window.sessionStorage.getItem('token') || ''
      }
    })
      .then((response) => {
        return checkStatus(response)
      })
      .then((res) => {
        return checkCode(res)
      })
  },
  upload (url, data) {
    return axios({
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': window.sessionStorage.getItem('token') || ''
      },
      withCredentials: false,
      method: 'post',
      url: url,
      data: data,
      timeout: 500000
    })
      .then((response) => {
        return checkStatus(response)
      })
      .then((res) => {
        return checkCode(res)
      })
  },
  export (url, data) {
    return axios({
      method: 'get',
      url,
      params: params(data),
      withCredentials: false,
      responseType: 'blob',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': window.sessionStorage.getItem('token') || ''
      }
    })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        let downloadFileName = response.headers['content-disposition'] || ''
        if (downloadFileName != '') {
          downloadFileName = downloadFileName.split('; filename=')[1]
        } else {
          downloadFileName = 'excel.xls'
        }
        link.setAttribute('download', decodeURIComponent(downloadFileName))
        document.body.appendChild(link)
        link.click()
      })
  }
}
